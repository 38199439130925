<template>
  <router-view />
</template>


<script>

 
export default {
  name: "App",
 
}




</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
  border: 0;
}

.content {
  background-color: #fff !important;
}
.font-oswald {
  font-family: "Oswald", sans-serif;
}
.font-roboto {
  font-family: 'Roboto', sans-serif;
}

</style>