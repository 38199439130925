<template>
  <Container>
    <div
      class="offcanvas offcanvas-end"
      style="width: 80%"
      tabindex="-1"
      id="acao_editar"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <button
          type="button"
          class="btn btn-danger ms-auto"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          Fechar
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="container-fluid row">
          <div class="col-md-12 text-center mb-1">
            <h2 class="offcanvas-title " id="offcanvasRightLabel">
            <b>  Editar Contato</b><hr>
            </h2>
          </div>

          <form class="row">
            <div class="col-md-4 form-group">
              <label class="col-form-label fs-3 ">Nome</label>
              <input
                type="text"
                class="form-control form-pad"
                v-model="obj.nome"
              />
            </div>
          
            <div class="col-md-4 form-group ">
              <label class="col-form-label fs-3 ">Telefone</label>

              <input
                type="text"
                class="form-control form-pad"
                v-mask="mascara.fone"
               
                v-model="obj.fone"
              />
            </div>

            <div class="col-md-4 form-group ">
              <label class="col-form-label fs-3 ">Email</label>
              <div class="">
                <input
                  type="email"
                  class="form-control form-pad"
                  v-model="obj.email"
                />
              </div>
            </div>

            
            <div class="col-md-2 mt-3 form-group">
              <label class="col-form-label fs-3 ">Gênero</label>

              <select class="form-select form-pad" v-model="obj.genero">
                <option value="0" selected>Selecione</option>
                <option value="Masculino">MASCULINO</option>
                <option value="Feminino">FEMININO</option>
                <option value="LGBTQIA+">LGBTQIA+</option>
              </select>
            </div>

            <div class="col-md-2 mt-3 form-group">
              <label class="col-form-label fs-3 ">Nascimento</label>
              <input
                type="text"
                class="form-control form-pad"
                v-mask="mascara.nascimento"
                v-model="nascimento"
              />
            </div>

            <div class="col-md-3 form-group mt-3">
              <label class="col-form-label fs-3 ">Grupo</label>

              <select v-model="obj.grupo_id" class="form-select form-pad">
                <option value="0" @change="Grupo_bd" disabled>Selecione o grupo</option>
                <option
                  v-for="grupo in grupos"
                  :value="grupo.id"
                  :key="grupo.id"
                  
                                    
                >
                  {{ grupo.nome_grupo }}
                </option>
              </select>
            </div>
            
            
            <Locais
            
              :Atualizar="AtualizaLocal"
              :cidadein="this.obj.cidade"
              :estadoin="this.obj.uf"
              :user="user.id"
            />

            <div class="col-md-12 form-group mt-2">
              <label class="col-form-label fs-3 ">Observacao</label>
              <div class="">
                <textarea
                  class="form-control"
                  cols="5"
                  rows="2"
                  v-model="obj.observacao"
                ></textarea>
              </div>
            </div>
            <div class="col-md-2 mt-3 ">
              <button
                type="button"
                class="btn btn-primary btn-block form-pad"
                @click="Salvar_edicao"
              >
                SALVAR
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    
  </Container>
</template>

<script>
import Container from "@/components/GLOBALS/container.vue";
import Locais from "@/components/GLOBALS/CidadesEstado.vue";
import Api from "@/services/api";
import Swal from "sweetalert2";

export default {
  name: "EditarUsuario",
  components: { Container, Locais },

  props: {
    grupos: {
      type: Array
    },
    user: {
      type: Object,
    },
    Atualiza: { type: Function },
  },
  data() {
    return {
      id: "",
      nascimento: "",
      obj: {
        id: localStorage.getItem("@USER_ID"),
        nome: "",
        contato_id: "",
        genero: "0",
        dia_aniversario: "",
        mes_aniversario: "",
        ano_aniversario: "",
        observacao: "",
        email: "",
        cidade: 0,
        uf: 0,
        grupo_id: "",
        fone:"",
      },

      mascara: {
        nascimento: "##/##/####",
        fone: "##(##)#########",
      },
    };
  },
  methods: {
    AtualizaLocal(uf, cidade) {
      this.obj.uf = uf;
      this.obj.cidade = cidade;
    },
    GetUser() {
      this.obj.nome = this.user.nome;
      this.obj.fone = this.user.fone;
      this.obj.contato_id = this.user.id;
      this.obj.genero = this.user.genero;
      this.nascimento = `${this.user.dia_aniversario}/${this.user.mes_aniversario}/${this.user.ano_aniversario}`;
      this.obj.observacao = this.user.observacao;
      this.obj.email = this.user.email;
      this.obj.grupo_id = parseInt(this.user.grupo_id);
      this.obj.cidade = parseInt(this.user.cidade);
      this.obj.uf = parseInt(this.user.uf);      
    },
    Grupo_bd(){
      Api.post(
        `/grupos/ZmlsYWRlYXRlbmRpbWVudG8=`,
        id
      )
        .then((response) => {
        //  console.log(response)
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
          });
        });

    },
    Salvar_edicao() {
      Api.post(
        `/contatos_update/ZmlsYWRlYXRlbmRpbWVudG8=`,

        this.obj
      )
        .then((response) => {
          if (response.data.status) {
            Swal.fire({
              icon: "success",
              title: response.data.mensagem,
            });
            this.Atualiza();
            const truck_modal = document.querySelector("#acao_editar");
            const modal = bootstrap.Offcanvas.getOrCreateInstance(truck_modal);
            modal.hide();
          } else {
            Swal.fire({
              icon: "error",
              title: response.data.mensagem,
            });
          }
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
          });
        });
      this.btn = true;
    },
  },
  watch: {
    user: function () {
      this.GetUser();
      this.btn = true;
    },
    nascimento: function () {
      this.obj.dia_aniversario = this.nascimento.substring(0, 2);
      this.obj.mes_aniversario = this.nascimento.substring(3, 5);
      this.obj.ano_aniversario = this.nascimento.substring(6, 11);
    },
  },
};
</script>

<style>
.form-pad {
  padding: 10px 20px !important;
}

@media (max-width: 600px) {
  button {
    width: 100%;
  }
}
</style>
