import { render, staticRenderFns } from "./BannerForgot.vue?vue&type=template&id=4e20df14&scoped=true&"
import script from "./BannerForgot.vue?vue&type=script&lang=js&"
export * from "./BannerForgot.vue?vue&type=script&lang=js&"
import style0 from "./BannerForgot.vue?vue&type=style&index=0&id=4e20df14&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e20df14",
  null
  
)

export default component.exports