<template>
  <div class="container mt-5">
    <FullCalendar
      :options="calendarOptions"
      :selectable="true"
      style="max-height: 600px"
    />

    <!-- OffCanvas -->
    <div
      class="offcanvas offcanvas-end"
      style="width: 85%"
      tabindex="-1"
      id="editarGrupos"
      aria-labelledby="editarGrupos"
    >
      <div class="offcanvas-header">
        <button
          type="button"
          class="btn btn-danger fs-4 fw-bold ms-auto"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          Fechar
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="container-fluid row">
            <h1>TESTE TESTE TESTE</h1>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";

export default {
  name: "calendario",
  components: {
    FullCalendar, // make the <FullCalendar> tag available
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: "dayGridMonth",
        eventClick: function () {
          alert("opa opa opa");
        },
      },
    };
  },
  methods: {
    selecionadia(){
        this.dia = this.day;
    },

    falaDele() {
      console.log("aiaiaiaiaiai");
    },
  },
};
</script>



