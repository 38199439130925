import Vue from "vue";
import VueRouter from "vue-router";
import index from "@/views/index.vue";
import login from "@/views/login.vue";
import atendimento from "@/views/atendimento.vue";
import register from "@/views/register.vue";
import admin from '@/views/admin.vue';
import calendario from '@/views/calendario.vue';
import politica_privacidade from '@/views/politicaPrivacidade.vue';
import Middleware from "@/services/middleware";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
  },
  {
    path: "/login",
    name: "login",
    component: login,
  },
  {
    path: "/admin",
    name: "admin",
    component: admin,
    beforeEnter: Middleware.admin,
  },
  {
    path: "/politica_privacidade",
    name: "politica_privacidade",
    component: politica_privacidade,
  },
  {
    path: '/register',
    name: "register",
    component: register,
    params: true
  },
  {
    path: '/calendario',
    name: "calendario",
    component: calendario,
  },

  {
    path: "/atendimento",
    name: "atendimento",
    component: atendimento,
    beforeEnter: Middleware.atendimento,
  },

  { path: "/:pathMatch(.*)*", component: index },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
