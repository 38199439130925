<template>
  <Container class="mt-5">
    <div class="d-flex flex-column align-items-center">
      <h1 class="fw-bold">Consultórios</h1>
    </div>
    <div
      class="d-flex usuarios text-center align-items-center p-4 justify-content-around flex-wrap"
    >
      <div class="col-12">
        <input
          type="text"
          class="buscar_input rounded pad-header m-1 w-50"
          placeholder="Busque Consultorios"
          v-model="pesquisa"
        />
        <button
          class="buscar_button m-1 bg-primary text-white text-uppercase fw-bold rounded pad-header"
        >
          Buscar
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table table-striped table-hover mb-4">
        <thead>
          <tr>
            <th scope="col">Consultório</th>

            <th scope="col">Ação</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Quiropraxia</td>
            <td
              class="d-flex gap-3 flex-wrap"
              style="align-items: center; justify-content: center"
            >
              <button
                title="Editar"
                class="btn btn-primary fs-3"
                data-bs-toggle="offcanvas"
                data-bs-target="#acao_editar"
                @click="marcarConsulta()"
              >
                Marcar Consulta
              </button>
            </td>
          </tr>
          <tr>
            <td>Neurologia</td>
            <td
              class="d-flex gap-3 flex-wrap"
              style="align-items: center; justify-content: center"
            >
              <button
                title="Editar"
                class="btn btn-primary fs-3"
                data-bs-toggle="offcanvas"
                data-bs-target="#acao_editar"
                @click="marcarConsulta()"
              >
                Marcar Consulta
              </button>
            </td>
          </tr>
          <tr>
            <td>Cardiologia</td>
            <td
              class="d-flex gap-3 flex-wrap"
              style="align-items: center; justify-content: center"
            >
              <button
                title="Editar"
                class="btn btn-primary fs-3"
                data-bs-toggle="offcanvas"
                data-bs-target="#acao_editar"
                @click="marcarConsulta()"
              >
                Marcar Consulta
              </button>
            </td>
          </tr>
          <!-- <tr v-for="(contato, index) in contatos" :key="index">
              <td>{{ contato.nome }}</td>
              <td>{{ contato.fone }}</td>
  
            
            </tr> -->
        </tbody>
      </table>
    </div>

    <div
      class="offcanvas offcanvas-end"
      style="width: 85%"
      tabindex="-1"
      id="marcarConsulta"
      aria-labelledby="marcarConsulta"
    >
      <div class="offcanvas-header flex-wrap mt-2">
        <h2 class="offcanvas-title fs-3 fw-bold" id="offcanvasRightLabel">
          Marcar Consulta
        </h2>
        <button
          type="button"
          class="btn btn-danger fs-4 fw-bold"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          Fechar
        </button>
      </div>
      <div class="row container-fluid">
        <h1 class="col-md-12 text-center">Selecione um dos médicos disponíveis</h1>

        <div class="col-md-12 mt-5">
          <div
            class="table-responsive d-flex flex-column justify-content-center align-items-center tabela-imprimir"
          >
            <table class="table table-striped table-hover mb-4">
              <thead>
                <tr>
                  <th scope="col">Nome</th>
                  <th scope="col">Contato</th>
                  <th scope="col">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Guilherme</td>
                  <td>guilherme@gmail.com</td>
                  <td>
                    <button class="btn btn-primary">Selecionar</button>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <nav aria-label="Page navigation example">
              <ul class="pagination text-center">
                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>

                <li
                  class="page-item"
                  v-for="(list, index) in list_page"
                  :key="index"
                >
                  <a
                    v-show="
                      list.label !== '&laquo; Previous' &&
                      list.label !== 'Next &raquo;'
                    "
                    @click="selecionarPagina(list.label)"
                    class="page-link"
                    :class="{ active: list.active }"
                    href="#"
                    >{{ list.label }}</a
                  >
                </li>

                <li class="page-item">
                  <a class="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav> -->
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>
  
  
  
  <script>
import Container from "@/components/GLOBALS/container.vue";

export default {
  name: "ConsultasPaciente",
  components: {
    Container,
  },
  data() {
    return {
      pesquisa: "",
    };
  },
  methods: {
    marcarConsulta() {
      const truck_offCanvas = document.querySelector("#marcarConsulta");
      const offCanvas = bootstrap.Offcanvas.getOrCreateInstance(truck_offCanvas);
      offCanvas.show();
    },
  },
};
</script>
  
  
  <style>
.pad-header {
  padding: 10px;
}

.usuarios input {
  border: 1px solid #d0d0d0;
}

.table {
  font-family: "Roboto", sans-serif;
}

.usuarios {
  border-bottom: 2px solid #ababab;
}

@media (max-width: 645px) {
  .usuarios {
    justify-content: center;
    gap: 10px;
  }

  .usuarios div {
    margin: 0;
  }
}
@media (max-width: 430px) {
  .usuarios button,
  input {
    width: 100%;
  }
}

.table_section {
  overflow: auto;
  height: auto;
}

table {
  text-align: center;
  width: 100%;
  margin: auto;
  table-layout: fixed;
  min-width: 1000px;
}
table th {
  position: sticky;
  top: 0;
  background-color: #f6f9fc;
  color: #8493a5;
  font-size: 1.12rem;
}

th,
td {
  border-bottom: 1px solid #dddd;
  padding: 10px 20px;
}

td {
  font-size: 1.2rem;
}

tr:hover td {
  cursor: pointer;
  background-color: #f6f9fc;
}

td img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
}
</style>