<template>
  <div class="box">
    <FrontMenu :change="setSelect"/>
    <div class="content ">
      <BannerLogin v-show="selected === option[0].name" :change="setSelect"/>
      <BannerForgot v-show="selected === option[1].name" :change="setSelect"/>
    </div>
  </div>
</template>

<script>
import BannerLogin from "@/components/login/BannerLogin.vue";
import FrontMenu from "@/components/GLOBALS/FrontMenu.vue";
import BannerForgot from "@/components/login/BannerForgot.vue";
export default {
  name: "loginPage",
  components: { BannerLogin, FrontMenu, BannerForgot },
  data() {
    return {
      selected: 'login',
      option: [
        {
          name: "login",
          target: "LOGIN",
        },
        {
          name: "forgot",
          target: "FORGOT",
        },
      ],
    };
  },
  methods: {
    setSelect(name) {
      this.selected = name;
    },
  },
};
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  background-image: linear-gradient(to right, #7ED0DD, #479fcf);
  height: 100vh;
}
</style>
