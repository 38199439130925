<template>
  <div class="main" v-if="plano_id != 3">
    <div
      class="fundo-transparente"
      @click="fecharEscolhas"
      v-if="abrirEscolha"
    ></div>
    <div class="box" :class="abrirMsg ? 'chatAtivo' : ''">
      <div class="leftSide">
        <div class="header">
          <div
            class="bg-primary text-white"
            :class="{ fila: opcaoSelecionada == 'fila' }"
            @click="chamarAtendimentosFila()"
          >
            <span class="fw-bold text-center">FILA </span>
            <span class="badge bg-warning rounded-pill" v-if="fila_qtd">
              {{ fila_qtd }}</span
            >
          </div>
          <div
            class="bg-success text-white"
            :class="{ atendimento: opcaoSelecionada == 'atendimento' }"
            @click="chamarMeusAtendimentos()"
          >
            <span class="fw-bold text-center"
              >MEUS<span class="badge bg-warning rounded-pill" v-if="meusatendimentos_qtd">
              {{ meusatendimentos_qtd }}</span><br />
              ATENDIMENTOS
            </span> 
          </div>
          <div
            class="bg-warning text-white"
            :class="{ interno: opcaoSelecionada == 'interno' }"
            @click="chamarMeusChatsInternos()"
          >
            <span class="fw-bold text-center">CONVERSAS <br />INTERNAS</span>
          </div>

          <div class="bg-primary text-white" @click="sair()">
            <span class="fw-bold text-center">SAIR </span>
          </div>
        </div>

        <div class="search autocomplete d-flex flex-column">
          <div class="input d-flex gap-2">
            <input
              type="text"
              placeholder="Pesquise Contato"
              v-model="pesquisa"
              @keyup.enter="Pesquisar"
              id="myInput"
              style="flex: 6"
            />
            <button
              class="btn btn-success text-uppercase fs-1"
              data-bs-toggle="offcanvas"
              data-bs-target="#add_user"
              style="flex: 1"
            >
              +
            </button>
          </div>

          <span
            class="icone-pesquisa"
            :class="novaTransferencia ? 'layoutNovaTransferencia' : ''"
          >
            <i class="fas fa-search"></i>
          </span>

          <div
            v-if="novaTransferencia"
            class="fw-bold text-warning text-center"
          >
            VOCÊ RECEBEU UMA NOVA TRANSFERÊNCIA!
          </div>

          <span
            class="icone-pesquisa"
            :class="novaTransferencia ? 'layoutNovaTransferencia' : ''"
          >
            <i class="fas fa-search"></i>
          </span>
          <div v-if="mensageminterna" class="fw-bold text-warning text-center">
            NOVA MENSAGEM INTERNA!
          </div>
        </div>

        <span v-if="pesquisa" class="contatos">
          <!-- Pesquisando contatos -->
          <lista-atendimentos
            :lista="listaContatosPesquisa"
            :change="abrirConversa"
            :ativado="ativado"
            :tipo="tipo_usuario"
            :novo="notificacao"
            :lista_fone="lista_fones_notificados"
            :qtdmensagens="qtdmensagens"
          />
        </span>
        <span v-else class="contatos">
          <!-- Listagem de Contatos -->

          <span v-if="listaContatosInterno">
            <lista-atendimentos-chat-interno
              :lista="listaContatosInterno"
              :change="abrirConversaChatInterno"
              :ativado="ativado"
              :novointerno="qtdmensagensinternas"
            />
          </span>
          <span v-else>
            <lista-atendimentos
              :lista="listaContatos"
              :change="abrirConversa"
              :ativado="ativado"
              :tipo="tipo_usuario"
              :novo="notificacao"
              :lista_fone="lista_fones_notificados"
              :qtdmensagens="qtdmensagens"
            />
          </span>
        </span>
      </div>

      <div class="rightSide" v-if="abrirMsg">
        <span v-if="listaContatosInterno">
          <div class="headerConversation">
            <div class="img-text" @click="voltar">
              <i class="fas fa-chevron-left"></i>
              <img :src="selecionado.foto" alt="" />
              <h5 class="ps-2 fs-5">{{ selecionado.nome }}</h5>
            </div>
            <div class="responsive" @click="responsivo()">
              <i class="fas fa-ellipsis-v fs-2"></i>
              <div class="sub-menu" v-show="abrirDropdown">
                <button
                  type="button"
                  class="btn btn-danger"
                  @click="fecharAtendimentoContatoInterno()"
                >
                  <i class="fas fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div
              class="btn-group"
              role="group"
              aria-label="Basic mixed styles example"
            >
              <button
                type="button"
                class="btn btn-danger"
                @click="fecharAtendimentoContatoInterno()"
              >
                <i class="fas fa-times" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <chat-atendimento-contatos-interno
            :mensagens="mensagens"
            :idAtendido="foneConversa"
          />
        </span>
        <span v-else>
          <div class="headerConversation">
            <div class="img-text" @click="voltar">
              <i class="fas fa-chevron-left"></i>
              <img :src="selecionado.foto" alt="" />
              <h5 class="ps-2 fs-5">{{ selecionado.nome }} - {{ selecionado.fone }}</h5>
              
            </div>
            <div class="responsive" @click="responsivo()">
              <i class="fas fa-ellipsis-v fs-2"></i>
              <div class="sub-menu" v-show="abrirDropdown">
                <button
                  type="button"
                  class="btn"
                  :class="atendimentoStatus ? 'btn-success' : 'btn-danger'"
                  @click="bloqueiaAtendimento(selecionado.id)"
                >
                  <i class="fas fa-check" aria-hidden="true"></i>
                </button>
<!-- 
                <button type="button" class="btn btn-primary">
                  <i class="fas fa-camera" aria-hidden="true"></i>
                </button> -->
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#acao_editar"
                >
                  <i class="fas fa-plus" aria-hidden="true"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-info"
                  data-bs-toggle="modal"
                  data-bs-target="#transfereAtendimento"
                >
                  <i class="fas fa-sync-alt"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  @click="fecharAtendimento(selecionado.id_atendimento)"
                >
                  <i class="fas fa-times" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <div
              class="btn-group"
              role="group"
              aria-label="Basic mixed styles example"
            >
              <button
                type="button"
                class="btn"
                :class="atendimentoStatus ? 'btn-success' : 'btn-danger'"
                @click="bloqueiaAtendimento(selecionado.id)"
              >
                <i class="fas fa-check" aria-hidden="true"></i>
              </button>
              <!-- <button type="button" class="btn btn-primary">
                <i class="fas fa-camera" aria-hidden="true"></i>
              </button> -->
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-toggle="offcanvas"
                data-bs-target="#acao_editar"
              >
                <i class="fas fa-plus" aria-hidden="true"></i>
              </button>
              <button
                type="button"
                class="btn btn-info"
                data-bs-toggle="modal"
                data-bs-target="#transfereAtendimento"
              >
                <i class="fas fa-sync-alt"></i>
              </button>
              <button
                type="button"
                class="btn btn-danger"
                @click="fecharAtendimento(selecionado.id_atendimento)"
              >
                <i class="fas fa-times" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <chat-atendimento
            :abrir-conversa="abrirConversaContatoEncaminhado"
            :mensagens="mensagens"
            :processando = "processando"
            :mensagemdireta = "mensagemdireta"
            :status="status_chat"
            :foneAtendido="foneConversa"
            :alterarLayoutBatePapo="alterarLayoutBatePapo"
            :responder-layout="responderLayout"
            :abremodal_apagarmensagem = "abremodal_apagarmensagem"
            :estado-encaminhar-mensagens="estadoEncaminharMensagens"
            :estado-responder-mensagem="estadoResponderMensagem"
            :lista-mensagens-selecionadas="listaMensagensSelecionadas"
          />
        </span>

        <div
          class="chat-message-label position-relative"
          style="background-color: #f0f2f5 !important"
          v-if="estadoResponderMensagem"
        >
          <div class="caixa">{{ mensagemResponder }}</div>
          <div class="input-group-prepend">
            <span
              style="padding: 10px; margin-left: auto"
              class="cursor-pointer"
              @click="fecharResponderLayout()"
            >
              <img
                src="../assets/close.png"
                alt=""
                style="width: 16px; height: 16px"
              />
            </span>
          </div>
        </div>

        <span v-if="listaContatosInterno">
          <div
            class="chat-message chatbox_input d-flex gap-3 align-items-center justify-content-center position-relative"
            style="background-color: #f0f2f5 !important"
          >
            <div class="input-group-prepend" v-if="!layoutGravandoAudio">
              <span style="padding: 10px" @click="abrirEscolhas">
                <img src="../assets/paper-clip.png" alt="" />
              </span>

              <div
                class="menu-escolhas d-flex flex-column gap-3 layout-chat-interno"
                v-if="abrirEscolha"
              >
                <div
                  class="button-radius bg-danger"
                  title="Arquivos"
                  data-bs-toggle="modal"
                  data-bs-target="#mandarArquivoChatInterno"
                >
                  <i class="fas fa-file-upload fs-3"></i>
                </div>
              </div>
            </div>

            <div class="input-group-prepend" style="margin-left: 10px">
              <emoji-picker @emoji="insert">
                <div
                  slot="emoji-invoker"
                  slot-scope="{ events: { click: clickEvent } }"
                  @click.stop="clickEvent"
                >
                  <button type="button">
                    <i class="fas fa-smile fs-3"></i>
                  </button>
                </div>
                <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                  <div>
                    <div class="emoji-picker">
                      <div
                        v-for="(emojiGroup, category) in emojis"
                        :key="category"
                      >
                        <h5>{{ category }}</h5>
                        <div>
                          <span
                            v-for="(emoji, emojiName) in emojiGroup"
                            :key="emojiName"
                            @click="insert(emoji)"
                            :title="emojiName"
                            class="tamanho-3"
                            >{{ emoji }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </emoji-picker>
            </div>

            <div class="input-group mb-0 align-items-center">
              <textarea
                class="form-control"
                placeholder="Mensagem"
                v-model="mensagem"
                @keyup.enter.exact="enviarMensagemChatInterno()"
              ></textarea>
             
              <!-- <div class="input-group-prepend">
                <span
                  class="input-group-text bg-primary"
                  style="padding: 15px 20px"
                  @click="enviarMensagemChatInterno"
                >
                  <img src="../assets/enviar.png" alt="" />
                </span>
              </div> -->
              <div class="input-group-prepend" v-if="mensagem">
                <span
                  class="input-group-text bg-primary"
                  style="padding: 15px 20px"
                  @click="enviarMensagemChatInterno"
                >
                  <img src="../assets/enviar.png" alt="" />
                </span>
              </div>
              <!--  Gravador record chatinterno
                <div
                class="input-group-prepend"
                :class="layoutGravandoAudio ? 'ml-auto' : ''"
                v-else
              >
                <span
                  class="record-settings"
                  style="padding: 10px 15px"
                  v-if="!layoutGravandoAudio"
                >
                  <vue-record-audio
                    style="width: 50px; height: 50px"
                    @stream="onStream"
                    @result="onResult"
                  />
                </span>

                <div class="recorded-audio d-flex align-items-center">
                  <div
                    v-for="(record, index) in recordings"
                    :key="index"
                    class="recorded-item d-flex align-items-center"
                  >
                    <div class="audio-container">
                      <audio :src="record.src" controls />
                    </div>
                    <div
                      class="deletar-audio"
                      style="padding: 10px 20px"
                      @click="removeRecord(index)"
                    >
                      <img src="../assets/trash.png" />
                    </div>
                  </div>
                </div>
              </div> -->

              <div
                class="enviar-audio bg-primary text-center cursor-pointer"
                style="padding: 15px; border-radius: 50%"
                v-if="layoutGravandoAudio"
                @click="enviaGravacaoAudio"
              >
                <img src="../assets/enviar.png" alt="" />
              </div>
            </div>
          </div>
        </span>
        <span v-else>
          <div
            class="chat-message layout_encaminharMensagem chatbox_input bg-message position-relative"
            v-if="estadoEncaminharMensagens"
          >
            <div class="input-group-prepend">
              <span
                style="padding: 10px"
                class="cursor-pointer"
                @click="fecharLayoutBatePapo()"
              >
                <img
                  src="../assets/close.png"
                  alt=""
                  style="width: 16px; height: 16px"
                />
              </span>
            </div>

            <div class="input-group-prepend fs-16">
              {{ listaMensagensSelecionadas.length }} Selecionadas
            </div>

            <span
              style="padding: 10px"
              class="cursor-pointer"
              data-bs-toggle="modal"
              data-bs-target="#encaminhaMensagens"
              v-if="listaMensagensSelecionadas.length !== 0"
            >
              <i class="fas fa-share fs-3"></i>
            </span>
            <span style="padding: 10px" class="cursor-pointer" v-else>
              <i class="fas fa-share fs-3"></i>
            </span>
          </div>

          <div
            class="chat-message chatbox_input d-flex gap-3 align-items-center justify-content-center position-relative"
            style="background-color: #f0f2f5 !important"
            v-else
          >

            <div class="input-group-prepend" v-if="!layoutGravandoAudio">
              <span style="padding: 10px" @click="abrirEscolhas">
                <img src="../assets/paper-clip.png" alt="" />
              </span>

              <div
                class="menu-escolhas d-flex flex-column gap-3"
                v-if="abrirEscolha"
              >
                <div
                  class="button-radius bg-warning"
                  title="Foto e Vídeo"
                  data-bs-toggle="modal"
                  data-bs-target="#mandarArquivo"
                >
                  <i class="fas fa-images fs-3"></i>
                </div>
                <div
                  class="button-radius bg-danger"
                  title="Arquivos"
                  data-bs-toggle="modal"
                  data-bs-target="#mandarArquivo"
                >
                  <i class="fas fa-file-upload fs-3"></i>
                </div>
                <div
                  class="button-radius bg-primary"
                  title="Contatos"
                  data-bs-toggle="modal"
                  data-bs-target="#compartilharContato"
                >
                  <i class="fas fa-user fs-3"></i>
                </div>
              </div>
            </div>

            <div class="input-group-prepend" v-if="!layoutGravandoAudio">
              <emoji-picker @emoji="insert">
                <div
                  slot="emoji-invoker"
                  slot-scope="{ events: { click: clickEvent } }"
                  @click.stop="clickEvent"
                >
                  <button type="button">
                    <i class="fas fa-smile fs-3"></i>
                  </button>
                </div>
                <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                  <div>
                    <div class="emoji-picker">
                      <div
                        v-for="(emojiGroup, category) in emojis"
                        :key="category"
                      >
                        <h5>{{ category }}</h5>
                        <div>
                          <span
                            v-for="(emoji, emojiName) in emojiGroup"
                            :key="emojiName"
                            @click="insert(emoji)"
                            :title="emojiName"
                            class="tamanho-3"
                            >{{ emoji }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </emoji-picker>
            </div>

            <div class="input-group mb-0 align-items-center" v-if="message_id">
              <textarea
                class="form-control"
                placeholder="Mensagem"
                v-model="mensagem"
                @keyup.enter.exact="enviarMensagem(2)"
                v-if="!layoutGravandoAudio"
              ></textarea>

              <div class="input-group-prepend" v-if="mensagem">
                <span
                  class="input-group-text bg-primary"
                  style="padding: 15px 20px"
                  @click="enviarMensagem(2)"
                >
                  <img src="../assets/enviar.png" alt="" />
                </span>
              </div>
              <div
                v-else
                class="input-group-prepend"
                :class="layoutGravandoAudio ? 'ml-auto' : ''"
              >
                <span
                  class="record-settings"
                  style="padding: 10px 15px"
                  v-if="!layoutGravandoAudio"
                >
                  <vue-record-audio
                    style="width: 50px; height: 50px"
                    @stream="onStream"
                    @result="onResult"
                  />
                </span>

                <div class="recorded-audio d-flex align-items-center">
                  <div
                    v-for="(record, index) in recordings"
                    :key="index"
                    class="recorded-item d-flex align-items-center"
                  >
                    <div class="audio-container">
                      <audio :src="record.src" controls />
                    </div>
                    <div
                      class="deletar-audio"
                      style="padding: 10px 20px"
                      @click="removeRecord(index)"
                    >
                      <img src="../assets/trash.png" />
                    </div>
                  </div>
                </div>
              </div> 
              <div
                class="enviar-audio bg-primary text-center"
                style="padding: 15px; border-radius: 50%"
                v-if="layoutGravandoAudio"
                @click="enviaGravacaoAudio"
              >
                <img src="../assets/enviar.png" alt="" />
              </div>
            </div> 

            <div class="input-group mb-0 align-items-center" v-else>
              <textarea
                class="form-control"
                placeholder="Mensagem"
                v-model="mensagem"
                @keyup.enter.exact="enviarMensagem(1)"
                v-if="!layoutGravandoAudio"
              ></textarea>

              <div class="input-group-prepend" v-if="mensagem">
                <span
                  class="input-group-text bg-primary"
                  style="padding: 15px 20px"
                  @click="enviarMensagem(1)"
                >
                  <img src="../assets/enviar.png" alt="" />
                </span>
              </div>
              <!-- Gravador audio Chat Zap 
                <div
                v-else
                class="input-group-prepend"
                :class="layoutGravandoAudio ? 'ml-auto' : ''"
              >
                <span
                  class="record-settings"
                  style="padding: 10px 15px"
                  v-if="!layoutGravandoAudio"
                >
                  <vue-record-audio
                    style="width: 50px; height: 50px"
                    @stream="onStream"
                    @result="onResult"
                  />
                </span>

                <div class="recorded-audio d-flex align-items-center">
                  <div
                    v-for="(record, index) in recordings"
                    :key="index"
                    class="recorded-item d-flex align-items-center"
                  >
                    <div class="audio-container">
                      <audio :src="record.src" controls />
                    </div>
                    <div
                      class="deletar-audio"
                      style="padding: 10px 20px"
                      @click="removeRecord(index)"
                    >
                      <img src="../assets/trash.png" />
                    </div>
                  </div>
                </div>
              </div>
 -->
              <div
                class="enviar-audio bg-primary text-center cursor-pointer"
                style="padding: 15px; border-radius: 50%"
                v-if="layoutGravandoAudio"
                @click="enviaGravacaoAudio"
              >
                <img src="../assets/enviar.png" alt="" />
              </div>
            </div>
          </div>
        </span>
      </div>
      <div class="rightSide bg-cinza apresentacao" v-else>
        <img src="@/assets/img/inzupt.png" alt="" width="60%" />
        <!-- <div class="config"></div> -->
        <span
          class="settings"
          data-bs-toggle="offcanvas"
          data-bs-target="#settings"
          aria-controls="staticBackdrop"
        >
          <img src="../assets/engrenagem.png" alt="" />
        </span>
      </div>
    </div>

    <span
      class="settings-mobile"
      data-bs-toggle="offcanvas"
      data-bs-target="#settings-mobile"
      aria-controls="settings-mobile"
    >
      <img src="../assets/engrenagem.png" alt="" />
    </span>
    <!-- OffCanvas -->
    <div
      class="offcanvas offcanvas-end"
      style="width: 45%"
      tabindex="-1"
      id="settings"
      aria-labelledby="settings"
    >
      <div class="offcanvas-header p-2">
        <button
          type="button"
          class="btn btn-danger fs-4 fw-bold ms-auto"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          Fechar
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="row container-fluid text-center">
          <h2
            class="offcanvas-title fs-3 fw-bold mb-4"
            id="offcanvasRightLabel"
          >
            Configurações Área de Atendimento
          </h2>

          <div class="col-md-12">
            <h2 style="font-size: 1.5rem" class="text-start">
              Notificação de atendimento
            </h2>

            <div class="d-flex justify-content-between mt-4">
              <h3 style="font-size: 1rem" class="text-start">
                Deseja Ativar notificação?
              </h3>
              <div class="form-check form-switch">
                <input
                  class="form-check-input fs-2"
                  type="checkbox"
                  :checked="audioStatus"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  @change="ativarNotificacao"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="offcanvas offcanvas-end"
      style="width: 90%"
      tabindex="-1"
      id="settings-mobile"
      aria-labelledby="settings-mobile"
    >
      <div class="offcanvas-header p-2">
        <button
          type="button"
          class="btn btn-danger fs-4 fw-bold ms-auto"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          Fechar
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="row container-fluid text-center">
          <h2
            class="offcanvas-title fs-3 fw-bold mb-4"
            id="offcanvasRightLabel"
          >
            Configurações Área de Atendimento
          </h2>

          <div class="col-md-12">
            <h2 style="font-size: 1.5rem" class="text-start">
              Notificação de atendimento
            </h2>

            <div class="d-flex justify-content-between mt-4">
              <h3 style="font-size: 1rem" class="text-start">
                Deseja Ativar notificação?
              </h3>
              <div class="form-check form-switch">
                <input
                  class="form-check-input fs-2"
                  type="checkbox"
                  :checked="audioStatus"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  @change="ativarNotificacao"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <EditarUsuario
      :user="selecionado"
      :grupos="this.grupos"
      :atualiza-frente="chamarMeusAtendimentos"
    />
    <transferir-atendimento
      :id_atendimento="selecionado.id_atendimento"
      :chamar-dados="chamarAtendimentosFila"
      :atualizaMeusAtendimentos="chamarMeusAtendimentos"
      :fechar-tela-de-conversa="fecharTelaDeConversa"
      
    />

    <apagarMensagem
      :mensagemdeleta ="mensagemapagar"
      :id_mensagem="message_id"
      :fone="selecionado.fone"
      :atualizarConversa="atualizarConversa"
    />

    <mandar-arquivo
      :nome-selecionado="selecionado.nome"
      :fone="selecionado.fone"
      :atualiza-conversa="atualizarConversa"
    />

    <mandar-arquivo-chat-interno
      :nome-selecionado="selecionado.nome"
      :id_transferido="selecionado.id"
      :atualiza-conversa="atualizarConversInterna"
    />

    <compartilhar-contato
      :fone="selecionado.fone"
      :atualiza-conversa="atualizarConversa"
    />
    <encaminha-mensagens
      :fone="selecionado.fone"
      :name="selecionado.nome"
      :limpa-array="limparArrayMensagensSelecionadas"
      :lista-mensagens-selecionadas="listaMensagensSelecionadas"
    />

    <adicionar-usuario />
  </div>
  <div class="main" v-else="plano_id == 3"><h1> Seu plano não possui Zapcenter</h1></div>


  
</template>

<script>
import ListaAtendimentos from "@/components/atendimento/ListaAtendimentos.vue";
import ListaAtendimentosChatInterno from "@/components/atendimento/ListaAtendimentosChatInterno.vue";
import ChatAtendimento from "@/components/atendimento/ChatAtendimento.vue";
import ChatAtendimentoContatosInterno from "@/components/atendimento/ChatAtendimentoContatosInterno.vue";
import Api from "@/services/api";
import EditarUsuario from "@/components/atendimento/acao/editarContato.vue";
import TransferirAtendimento from "@/components/atendimento/acao/transferirAtendimento.vue";
import apagarMensagem from "@/components/atendimento/acao/apagaMensagem.vue";
import mandarArquivo from "@/components/atendimento/acao/mandarArquivo.vue";
import mandarArquivoChatInterno from "@/components/atendimento/acao/mandarArquivoChatInterno.vue";

import compartilharContato from "@/components/atendimento/acao/compartilharContato.vue";
import encaminhaMensagens from "@/components/atendimento/acao/encaminhaMensagens.vue";
import adicionarUsuario from "@/components/atendimento/acao/ADDUSUARIOVUE.vue";

import AudioFila from "@/assets/audios/notifica.mp3";
import EmojiPicker from "vue-emoji-picker";
import middleware from "@/services/middleware";
import Swal from 'sweetalert2';

export default {
  name: "containerComponents",
  components: {
    ListaAtendimentos,
    ChatAtendimento,
    EditarUsuario,
    TransferirAtendimento,
    apagarMensagem,
    mandarArquivo,
    mandarArquivoChatInterno,
    compartilharContato,
    adicionarUsuario,
    EmojiPicker,
    ListaAtendimentosChatInterno,
    ChatAtendimentoContatosInterno,
    encaminhaMensagens,
  },
  data() {
    return {
      processando: false,
      mensagemdireta: "",
      mensagemapagar: "",
      plano_id: 0,
      mediaRecorder: null,
      chunks: [],
      base64: "",
      mensagem: "",
      listaContatos: [],
      listaContatosPesquisa: [],
      listaContatosInterno: null,
      requisaoApi: null,
      abrirMsg: false,
      abrirEscolha: false,
      abrirDropdown: false,
      localId: null,
      mensagens: [],
      foneConversa: null,
      selecionado: {},
      status_chat: null,
      grupos: [],
      dados: {},
      atendimentoStatus: null,
      ativado: 0,
      opcaoSelecionada: "atendimento",
      pesquisa: "",
      fila_qtd: "",
      meusatendimentos_qtd:"",
      notificacao: false,
      lista_fones_notificados: [],
      qtdmensagens: "",
      qtdconversas: 0,
      qtdmensagensinternas: 0,
      session: "",
      number: "",
      listaMensagensSelecionadas: [],
      estadoEncaminharMensagens: false,
      estadoResponderMensagem: false,
      mensagemResponder: "",
      message_id: null,
      tipo_usuario: null,
      audioStatus: null,

      recordMode: {
        audio: "hold",
        video: "press",
      },
      recordings: [],
      novaTransferencia: false,
      mensageminterna: false,

      layoutGravandoAudio: false,
      mimetype:"audio/webm"
    };
  },
  mounted() {
    this.session = localStorage.getItem("@SESSION");
    this.number = localStorage.getItem("@NUMBER");
    this.tipo_usuario = localStorage.getItem("@TIPO");
    this.audioStatus =
      localStorage.getItem("@STATUS_NOTIFICACAO") === "false" ? false : true;

    this.transferenciainterna();
    this.atualizafila();
    this.novamensagem();
    this.novamensageminterna();
    this.chamarMeusAtendimentos();
    this.chamaGrupo();
  },
  methods: {
    removeRecord(index) {
      this.layoutGravandoAudio = false;
      this.recordings.splice(index, 1);
    },

    startRecording() {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        this.mediaRecorder = new MediaRecorder(stream);

        this.mediaRecorder.ondataavailable = data => {
         
        }
        
        // this.mediaRecorder.addEventListener("dataavailable", (event) => {
        //   this.chunks.push(event.data);
        // });
        this.mediaRecorder.start();
        // console.log(this.mediaRecorder);
        setTimeout(() => {
          this.mediaRecorder.stop()
        
        }, 3000);
      });
    },
    stopRecording() {
      this.mediaRecorder.stop();
      const blob = new Blob(this.chunks, { type: "audio/ogg; codecs=opus" });
      const reader = new window.FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        console.log(reader.result);
      };
      const audioURL = URL.createObjectURL(blob);
      this.$refs.audio.src = audioURL;
      console.log(audioURL);
    },

    convertBlobToMp3() {

    },
    onStream(stream) {
     // console.log("erro");

      this.mediaRecorder = new MediaRecorder(stream);
   
   
   this.mediaRecorder.start();

      this.mediaRecorder.ondataavailable = (event) => {
        this.chunks.push(event.data);
      };
    },

    onResult(data) {
      this.recordings.push({
        src: window.URL.createObjectURL(data),
      });
      
      this.mediaRecorder.stop();

      const blob = new Blob(this.chunks, { type: "audio/mpeg" });
      const reader = new window.FileReader();

    
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.base64 = reader.result;
        console.log(this.base64)
      };

      this.layoutGravandoAudio = true;
    },

    enviaGravacaoAudio() {
      // return result.data;
      let obj = {
        user_id: localStorage.getItem("@USER_ID"),
        fone: this.selecionado.fone,
        midia: this.base64,
      };

     
      Api.post("/envia_audionovo/ZmlsYWRlYXRlbmRpbWVudG8=", obj)
        .then(() => {
          //          console.log("enviado");
          this.layoutGravandoAudio = false;

          this.recordings = [];
        })
        .catch((error) => {
          console.log(error);
        });
    },

    ativarNotificacao() {
    //  console.log(this.audioStatus);
      this.audioStatus = !this.audioStatus;
    //  console.log(this.audioStatus);
      localStorage.setItem("@STATUS_NOTIFICACAO", this.audioStatus);
    },

    fecharLayoutBatePapo() {
      this.estadoEncaminharMensagens = false;
      this.listaMensagensSelecionadas = [];
    },

    limparArrayMensagensSelecionadas() {
      this.listaMensagensSelecionadas = [];
      this.fecharLayoutBatePapo();
    },
    alterarLayoutBatePapo() {
      this.estadoEncaminharMensagens = true;
      this.estadoResponderMensagem = false;
    },
    responderLayout(mensagem, id) {
      this.estadoEncaminharMensagens = false;
      this.message_id = id;
      this.estadoResponderMensagem = true;
      this.mensagemResponder = mensagem;
    },

    abremodal_apagarmensagem(id, mensagem) {

      const abrirmodal = document.querySelector("#apagarmensagemmodal");
      const modal =bootstrap.Modal.getOrCreateInstance(abrirmodal);

          modal.show();
        this.mensagemapagar = mensagem;
          this.message_id = id;
       
    
    },
    fecharResponderLayout() {
      this.estadoResponderMensagem = false;
      this.listaMensagensSelecionadas = [];
    },
    async Pesquisar() {
      Api.post(`/busca_contatos/ZmlsYWRlYXRlbmRpbWVudG8=`, {
        id: localStorage.getItem("@USER_ID"),
        busca: this.pesquisa,
      })
        .then((response) => {
          let data = response.data;

          this.listaContatosPesquisa = data.contatos.data;
        })
        .catch((erro) => console.log(erro));
    },
    callback(msg) {
      console.debug("Event: ", msg);
    },
    toggle() {
      this.showRecorder = !this.showRecorder;
    },
    sair() {
      middleware.logout();
    },
    insert(emoji) {
      this.mensagem += emoji;
    },

    transferenciainterna() {
      const id = localStorage.getItem("@USER_ID");
      const instancia =
        "aW56YXBicmFzaWx2dWU=/" + this.session + "/TRANSFERENCIA/" + id;

      const ref = this.$firebase.database().ref(`/${instancia}`);
      const sessao = this.session;

      ref.on("value", (data) => {
        const values = data.val();
        this.recebeuNovaTransferencia();
        // this.chamarMeusAtendimentos();
      });
    },

    atualizafila() {
      const instancia = "aW56YXBicmFzaWx2dWU=/" + this.session + "/FILA";
      const ref = this.$firebase.database().ref(`/${instancia}`);
      const sessao = this.session;

      ref.on("value", (data) => {
        const values = data.val();

        if (this.audioStatus) {
          this.executaSom(AudioFila);
        }

        this.fila_qtd = values.fila;
        //this.chamarAtendimentosFila();
      });
    },

    novamensagem() {
      //      window.Echo.channel("mensagem").listen("Mensagem", (e) => {

      const instancia = "aW56YXBicmFzaWx2dWU=/" + this.session + "/MENSAGENS";
      const ref = this.$firebase.database().ref(`/${instancia}`);
      ref.on("value", (data) => {
        const values = data.val();
        this.atualizarConversa();
        // this.chamarAtendimentosFila();
        this.atualizarFilaMeusAtendimentos();
      });
    },

    novamensageminterna() {
      const id = localStorage.getItem("@USER_ID");
      const instancia =
        "aW56YXBicmFzaWx2dWU=/" + this.session + "/CHAT_INTERNO/" + id;
      const ref = this.$firebase.database().ref(`/${instancia}`);

      ref.on("value", (data) => {
        const values = data.val();

         
        this.qtdmensagensinternas = 1;

        this.recebeumensageminterna();
        this.atualizarConversInterna();
        //this.chamarMeusChatsInternos();
      });
    },

    enviarMensagem(status) {
      if (this.mensagem === "") return;
      // 1 - mensagem normal || 2 - responder
      let mensagem = this.mensagem;
      this.mensagem = "";
      let nome = localStorage.getItem(`@USER_NAME`) +'\r\n\t\t'+mensagem;
      let nova = {mensagem: nome, type: 'text'};
      
      if (this.mensagens == 'Nao ha mensagem para esse contato'){
       this.mensagens = 'processando...';
        
      } else
      {
         this.mensagens.push(nova);}
        
      let objEnviaMensagem = {
        user_id: localStorage.getItem(`@USER_ID`),
        fone: this.selecionado.fone,
        mensagem: mensagem,
        mensagem_id: this.message_id,
        status: status,
      };

      Api.post("/envia_mensagemnova/ZmlsYWRlYXRlbmRpbWVudG8=", objEnviaMensagem)
        .then((response) => {
        //  let mensagens = JSON.parse(response.data.retorno);
        //   // this.mensagens.push(response.response[0])
        //  let nova = (mensagens.response[0].body);
            
            // this.mensagemdireta =  response.data.retorno.response[0].body;
            //  this.atualizarConversa();
            if (response.data.erro == 'number_incorret'){
                           
              Swal.fire("Mensagem não enviada!", response.data.retorno+'Reconecte o celular!', "error");



               this.atualizarConversa();
            } 
            
            if (response.data.erro == 0){

              this.atualizarConversa();
            } 
            
            // else {

            //   this.mensagens = mensagem;
            // }

        })
        .catch((error) => {
          console.log(error);
        });

      this.fecharResponderLayout();

      this.message_id = null;
    },
    enviarMensagemChatInterno() {
      let mensagem = this.mensagem;
      this.mensagem = "";
      let nova = {de_chat: localStorage.getItem(`@USER_ID`), msg_chat: mensagem, type: 'text'}
      this.mensagens.push(nova)
      let objEnviaMensagem = {
        user_id: localStorage.getItem(`@USER_ID`),
        id_transferido: this.selecionado.id,
        mensagem: mensagem,
      };

      Api.post(
        "/mensagem_chat_interno/ZmlsYWRlYXRlbmRpbWVudG8=",
        objEnviaMensagem
      )
        .then(() => {
          this.atualizarConversInterna();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    chamaGrupo() {
      Api.post("/grupos/ZmlsYWRlYXRlbmRpbWVudG8=", {
        id: localStorage.getItem("@USER_ID"),
      })
        .then((response) => {
          this.grupos = response.data.grupos;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fecharAtendimento(id_atendimento) {
      Api.post("/fechar_atendimento/ZmlsYWRlYXRlbmRpbWVudG8=", {
        id_atendimento: id_atendimento,
      })
        .then(() => {
          this.abrirMsg = !this.abrirMsg;

          this.listaContatos = [];
          this.listaContatosPesquisa = [];

           this.chamarMeusAtendimentos();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    atualizarConversa() {
      this.processando = true;
      Api.post("/atualiza_conversas/ZmlsYWRlYXRlbmRpbWVudG8=", {
        id: localStorage.getItem("@USER_ID"),
        fone: this.selecionado.fone,
      })
        .then((response) => {
          this.status_chat = true;

          let data = response.data;
         
          if (typeof data === "string") {
            this.mensagens = data;

            // this.chamarMeusAtendimentos();
          } else {
            let mensagensInvertidas = data.conversas.slice(0).reverse();
            // console.log(mensagensInvertidas[99])
            this.mensagens = mensagensInvertidas;
            
            // this.chamarMeusAtendimentos();
            
              let listaQtdeMensagens = data.qtdmensagens;

            
               this.montarNotificacoes(listaQtdeMensagens);
          }
        })
        .catch((error) => console.log(error));
    },

    abrirConversaContatoEncaminhado(info) {
      let fone = info.mensagem;
      let nome_contato = info.contactName;

      let objConversas = {
        id: localStorage.getItem("@USER_ID"),
        fone: fone,
        nome_contato: nome_contato,
      };

      Api.post("/conversas_bd/ZmlsYWRlYXRlbmRpbWVudG8=", objConversas)
        .then((response) => {
          let data = response.data;

          let ativo = response.data.usuario;
          let qtd = data.qtd;

          this.notificacao = false;

          Api.post(`/busca_contatos/ZmlsYWRlYXRlbmRpbWVudG8=`, {
            id: localStorage.getItem("@USER_ID"),
            busca: info.mensagem,
          }).then((resposta) => {
            let dados = resposta.data;

            let contatoInfo = dados.contatos.data[0];

            this.selecionado = contatoInfo;

            this.mensagens = [];

            if (qtd > 0) {
              this.qtdconversas = qtd;
              this.status_chat = true;
              // pegando array de mensagens da requisição
              let mensagensInvertidas = data.conversas.slice(0).reverse();
              this.mensagens = mensagensInvertidas;
              this.foneConversa = this.selecionado.fone;
              this.ativado = this.selecionado.id;
              this.atendimentoStatus = ativo.ativo === 1 ? true : false;

              this.salvaConversa();
            } else {
              this.qtdconversas = qtd;
              this.status_chat = false;

              this.mensagens = data.mensagem;
              this.foneConversa = this.selecionado.fone;
              this.ativado = this.selecionado.id;
              this.atendimentoStatus = ativo.ativo === 1 ? true : false;
              this.salvaConversa();
            }

            this.chamarMeusAtendimentos();
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    abrirConversa(info_user) {
      this.selecionado = info_user.usuario;

      let objConversas = {
        id: localStorage.getItem("@USER_ID"),
        fone: this.selecionado.fone,
        nome_contato: this.selecionado.nome,
      };

      this.mensagens = [];
      Api.post("/conversas_bd/ZmlsYWRlYXRlbmRpbWVudG8=", objConversas)
        .then((response) => {
          let data = response.data;
          let ativo = response.data.usuario;
          let qtd = data.qtd;
          
          this.notificacao = false;

          if (qtd > 0) {
            this.qtdconversas = qtd;
            this.status_chat = true;
            // pegando array de mensagens da requisição
            let mensagensInvertidas = data.conversas.slice(0).reverse();
            this.mensagens = mensagensInvertidas;
            this.foneConversa = this.selecionado.fone;
            this.ativado = this.selecionado.id;
            this.atendimentoStatus = ativo.ativo === 1 ? true : false;

            this.salvaConversa();
          } else {
            this.qtdconversas = qtd;
            this.status_chat = false;

            this.mensagens = data.mensagem;
            this.foneConversa = this.selecionado.fone;
            this.ativado = this.selecionado.id;
            this.atendimentoStatus = ativo.ativo === 1 ? true : false;
            this.salvaConversa();
          }

          this.chamarMeusAtendimentos();
        })
        .catch((error) => console.log(error));

      this.abrirMsg = true;
    },

    salvaConversa() {
      let objConversas = {
        id: localStorage.getItem("@USER_ID"),
        fone: this.selecionado.fone,
      };

      Api.post("/conversas/ZmlsYWRlYXRlbmRpbWVudG8=", objConversas)
        .then(() => {})
        .catch((error) => console.log(error));
    },

    
    atualizarFilaMeusAtendimentos() {
      
      Api.post("/meus_atendimentos/ZmlsYWRlYXRlbmRpbWVudG8=", {
        id: localStorage.getItem("@USER_ID"),
        setor_id: localStorage.getItem("@SETOR_ID"),
      })
        .then((response) => {
          let data = response.data;
          this.listaContatos = data.meusatendimentos;
          // this.notificacao = false;
          this.fila_qtd = data.qtdfila_atendimento;

          let listaQtdeMensagens = data.qtdmensagens;

          // if (data.transferido_id !== 0) {
          //   //  this.recebeuNovaTransferencia();
          // }

          this.montarNotificacoes(listaQtdeMensagens);

          // this.lista_fone = this.lista_fones_notificados;
        })
        .catch((error) => console.log(error));
    },
    chamarMeusAtendimentos() {
      // this.opcaoSelecionada = "atendimento";

      if (this.listaContatosInterno) {
        this.abrirMsg = false;
      }

      this.listaContatosInterno = null;
      this.pesquisa = "";

      Api.post("/meus_atendimentos/ZmlsYWRlYXRlbmRpbWVudG8=", {
        id: localStorage.getItem("@USER_ID"),
        setor_id: localStorage.getItem("@SETOR_ID"),
      })
        .then((response) => {
          let data = response.data;
          this.listaContatos = data.meusatendimentos;
          // this.notificacao = false;
          this.fila_qtd = data.qtdfila_atendimento;
          this.meusatendimentos_qtd = data.qtdmeus_atendimentos;
          let listaQtdeMensagens = data.qtdmensagens;

          // if (data.transferido_id !== 0) {
          //   //  this.recebeuNovaTransferencia();
          // }

          this.montarNotificacoes(listaQtdeMensagens);

          // this.lista_fone = this.lista_fones_notificados;
        })
        .catch((error) => console.log(error));
    },
    recebeuNovaTransferencia() {
      this.novaTransferencia = true;

      setTimeout(() => {
        this.novaTransferencia = false;
      }, 1000);
    },
    recebeumensageminterna() {
      this.mensageminterna = true;

      setTimeout(() => {
        this.mensageminterna = false;
      }, 1000);
    },
    montarNotificacoes(lista_qtde_mensagens) {
      const fones_enviados = lista_qtde_mensagens.map(
        (usuarios) => usuarios.fone_enviado
      );

      this.lista_fones_notificados = [];

      for (let i = 0; i < this.listaContatos.length; i++) {
        let qtdeMensagensFone = lista_qtde_mensagens.filter((mensagem) => {
          return mensagem.fone_enviado == this.listaContatos[i].fone;
        }).length;

        let index = fones_enviados.findIndex(
          (val) => val.fone_enviado == this.listaContatos[i].fone
        );

        // // so rodar quando o numero que enviou mensagem não existe dentro de algum objeto do array
        if (index < 0) {
          let obj = {
            fone: this.listaContatos[i].fone,
            qtdeMensagens: qtdeMensagensFone,
          };

          this.lista_fones_notificados.push(obj);
        }
      }
    },

    chamarAtendimentosFila() {
      // this.opcaoSelecionada = "fila";

      if (this.listaContatosInterno) {
        this.abrirMsg = false;
      }

      this.listaContatosInterno = null;
      this.pesquisa = "";

      Api.post("/fila_atendimento/ZmlsYWRlYXRlbmRpbWVudG8=", {
        id: localStorage.getItem("@USER_ID"),
        setor_id: localStorage.getItem("@SETOR_ID"),
      })
        .then((response) => {
          let data = response.data;
          
          this.plano_id = data.plano;
          
          this.fila_qtd = response.data.fila.length;

          this.listaContatos = data.fila;

          // this.atualizaPerfil();
        })

        .catch((error) => console.log(error));
    },
    atualizaPerfil() {
      let objConversas = {
        id: localStorage.getItem("@USER_ID"),
      };

      Api.post("/atualiza_perfil/ZmlsYWRlYXRlbmRpbWVudG8=", objConversas)
        .then(() => {})
        .catch((error) => console.log(error));
    },
    // chamarMeusAtendimentos() {
    //   this.opcaoSelecionada = "atendimento";
    //   this.listaContatosInterno = null;

    //   this.pesquisa = "";
    //   this.listaContatos = this.dados.meusatendimentos;

    // },

    bloqueiaAtendimento(contato_id) {
      Api.post("/bloqueia_contato/ZmlsYWRlYXRlbmRpbWVudG8=", {
        id: localStorage.getItem("@USER_ID"),
        contato_id: contato_id,
      })
        .then((response) => {
          this.atendimentoStatus = response.data.status;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    abrirEscolhas() {
      this.abrirEscolha = !this.abrirEscolha;
    },

    fecharEscolhas() {
      this.abrirEscolha = false;
    },

    voltar() {
      this.abrirMsg = !this.abrirMsg;

      this.fecharLayoutBatePapo();
      this.fecharResponderLayout();
    },
    responsivo() {
      this.abrirDropdown = !this.abrirDropdown;
    },

    fecharTelaDeConversa() {
      this.abrirMsg = false;
    },

    executaSom(som) {
      if (som) {
        let audio = new Audio(som);
        audio.play();
      }
    },
    chamarMeusChatsInternos() {
      this.opcaoSelecionada = "interno";
      this.pesquisa = "";
      this.abrirMsg = false;

      Api.post("/meus_chat_interno/ZmlsYWRlYXRlbmRpbWVudG8=", {
        id: localStorage.getItem("@USER_ID"),
      })
        .then((response) => {
          let data = response.data;

          this.qtdmensagensinternas = data.lido;
          this.listaContatosInterno = data.atendentes;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    abrirConversaChatInterno(info_user) {
      this.selecionado = info_user.usuario;
      let objConversas = {
        id: localStorage.getItem("@USER_ID"),
        id_transferido: this.selecionado.id,
      };

      this.mensagens = [];
      Api.post("/conversa_chat_interno/ZmlsYWRlYXRlbmRpbWVudG8=", objConversas)
        .then((response) => {
          let data = response.data.conversas;

          // verificando se ta vindo vazio
          if (typeof data.mensagem === "string") {
            this.mensagens = [];
          } else {
            this.mensagens = data;

            this.foneConversa = this.selecionado.id;

            this.ativado = this.selecionado.id;

            this.qtdmensagensinternas = response.lido;
          }
        })
        .catch((error) => console.log(error));

      this.abrirMsg = true;
    },
    atualizarConversInterna() {
      let objConversas = {
        id: localStorage.getItem("@USER_ID"),
        id_transferido: this.selecionado.id,
      };

      this.mensagens = [];
      Api.post("/conversa_chat_interno/ZmlsYWRlYXRlbmRpbWVudG8=", objConversas)
        .then((response) => {
          let data = response.data.conversas;

          // verificando se ta vindo vazio
          if (typeof data.mensagem === "string") {
            this.mensagens = [];
          } else {
            this.mensagens = data;
            
            }
        })
        .catch((error) => console.log(error));
    },
    fecharAtendimentoContatoInterno() {
      this.abrirMsg = false;
    },
  },
};
</script>

<style scoped>
.responsive {
  display: none;
  position: relative;
}
.bola {
  border-radius: 50%;
  display: inline-block;
  height: 100px;
  width: 100px;
  border: 1px solid #000000;
  background-color: #ffff00;
}

.settings {
  position: absolute;
  top: 0;
  cursor: pointer;
  right: 0;
  padding: 10px;
}
.settings-mobile {
  display: none;
  position: absolute;
  top: -2px;
  left: 10px;
  cursor: pointer;
  padding: 5px;
}

.settings img {
  width: 45px;
  height: 45px;
}
.settings-mobile img {
  width: 30px;
  height: 30px;
}

.fila {
  background-color: #345fa0 !important;
}
.atendimento {
  background-color: #18775a !important;
}
.interno {
  background-color: #906f28 !important;
}

.sair {
  background-color: red !important;
}
.input-group-prepend {
  cursor: pointer;
}
.sub-menu {
  position: absolute;
  width: 60px;
  right: -10px;
  z-index: 5;
}

.button-radius {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 50%;
  margin-top: 5px;
}

.menu-escolhas {
  /* display: none !important; */
  position: absolute;
  top: -220px;
  left: 5px;
  z-index: 5;
}
.menu-escolhas.layout-chat-interno {
  top: -80px;
}

.menu-escolhas.on {
  display: block !important;
}

.fundo-transparente {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.sub-menu button {
  margin-top: 5px;
}

* {
  margin: 0;
  padding: 0;
}
img {
  max-width: 100%;
}
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background-color: blue; */

  background-image: linear-gradient(
    #f58634 0%,
    #f58634 130px,
    #dcdddc 130px,
    #dcdddc 100%
  ) !important;
}

.box {
  position: relative;
  width: 1396px;
  max-width: 90%;
  height: calc(100% - 70px);
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.06);
  display: flex;
}

.box .leftSide {
  padding-bottom: 30px;
  position: relative;
  flex: 30%;
  background-color: #fff;
}

.box .rightSide {
  position: relative;
  flex: 70%;
  /* background-color: #f0f2f5; */
  background-color: #fff;

  border-left: 3px solid #eaeaea;
}

.rightSide.apresentacao {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  position: relative;
  width: 100%;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.headerConversation {
  width: 100%;
  /* background-color: #eaeaea; */
  border-bottom: 2px solid #eaeaea;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerConversation h5 {
  position: absolute;
  left: 70px;
}

.header div {
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search {
  grid-column: span 3;
  border-bottom: 2px solid #eaeaea;
  position: relative;
  padding: 10px;
}

.deletar-audio {
  margin-right: auto;
}

.search input {
  width: 100%;
  outline: 0;
  border: 0;
  background-color: #eaeaea;
  font-size: 1.1rem;
  padding: 10px;
  padding-left: 30px;
}

.search .icone-pesquisa {
  position: absolute;
  left: 20px;
  bottom: 26px;
}
.search .layoutNovaTransferencia {
  left: 25px !important;
  bottom: 66px !important;
}

.bg-cinza-claro {
  background-color: #e3eaef !important;
}
.bg-message {
  background-color: #f0f2f5 !important;
}

.img-text {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.details {
  margin-left: 10px;
}

.imgbx img,
.img-text img {
  border-radius: 50%;
}

.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0;
  background: transparent;
  border: 0;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}

.tamanho-3 {
  font-size: 2rem;
}
.emoji-picker {
  position: absolute;
  top: -10rem;
  z-index: 1;
  font-family: Montserrat;
  size: 30px;
  border: 1px solid #ccc;
  max-width: 35rem;
  max-height: 10rem;
  overflow: scroll;
  overflow-x: hidden;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}

@media (max-width: 900px) {
  .box.chatAtivo .leftSide {
    display: none;
  }

  .settings-mobile {
    display: block;
  }
  .rightSide.apresentacao {
    display: none;
  }
  .box {
    margin: auto;
  }
  .leftSide {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .header {
    font-size: 0.7rem;
  }
  .btn-group {
    display: none;
  }

  .responsive {
    display: block;
  }
  .emoji-picker {
    max-width: 17rem !important;
    max-height: 10rem !important;
    overflow-x: hidden;
  }
}

.layout_encaminharMensagem {
  display: flex;
  padding: 8px;
  align-items: center;
}
.layout_encaminharMensagem span {
  margin-left: auto;
}

.cursor-pointer {
  cursor: pointer;
}
.fs-16 {
  font-size: 16px;
}

.chat-message-label {
  display: flex;
  margin: auto;
  align-items: center;
  /* justify-content: space-between; */
  background-color: #e3eaef;
  padding: 15px;
  justify-content: center;
}

.caixa {
  width: 90%;
  height: 50px;
  padding: 10px;
  border-radius: 2px;
  background-color: #e5e7ea;
  color: #7994a4;
  /* font-weight: bold; */
}

.ml-auto {
  margin-left: auto;
}

@media (max-width: 500px) {
  .box {
    height: calc(100% - 70px);
  }
}
</style>
