<template>
  <!-- <div>
    <div class="container mt-5" v-show="!create">
      <div class="row">
        <div class="col-md-12">
          <div class="osahan-account-page-right shadow-sm bg-white p-4 h-100">
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade active show"
                id="payments"
                role="tabpanel"
                aria-labelledby="payments-tab"
              >
                <div class="row">
                  <div class="col-6">
                    <h4 class="font-weight-bold mt-0 mb-4 text-center">
                      MEUS pagamento
                    </h4>
                  </div>
                  <div class="col-6">
                    <button class="btn btn-success" @click="activeCreate">
                      ADQUIRIR PLANO
                    </button>
                  </div>
                </div>
                <div class="row">
                  Status:
                  <div class="col-3">
                    <select name="" id="" class="form-control">
                      <option value="">Todos</option>
                      <option value="">Pendente</option>
                      <option value="">Pagos</option>
                      <option value="">Cancelado</option>
                    </select>
                  </div>
                </div>

                <div class="row pt-2">
                  <div class="col-md-3">
                    <div class="bg-white card payments-item shadow-sm">
                      <div class="gold-members p-4">
                        <a href="#"> </a>
                        <div class="media">
                          <div class="media-body">
                            <h6 class="mb-1">PAGO</h6>
                            <p class="text-black">24/08/2022</p>

                            <p class="mb-0 text-black font-weight-bold">
                              <a
                                class="text-danger"
                                data-toggle="modal"
                                data-target="#delete-address-modal"
                                href="#"
                                ><i class="icofont-ui-delete"></i> DETALHES</a
                              >
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="bg-white card payments-item shadow-sm">
                      <div class="gold-members p-4">
                        <a href="#"> </a>
                        <div class="media">
                          <div class="media-body">
                            <h6 class="mb-1">PENDENTE</h6>
                            <p class="text-black">24/08/2022</p>

                            <p class="mb-0 text-black font-weight-bold">
                              <a
                                class="text-danger"
                                data-toggle="modal"
                                data-target="#delete-address-modal"
                                href="#"
                                ><i class="icofont-ui-delete"></i> DETALHES</a
                              >
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="bg-white card payments-item shadow-sm">
                      <div class="gold-members p-4">
                        <a href="#"> </a>
                        <div class="media">
                          <div class="media-body">
                            <h6 class="mb-1">PAGO</h6>
                            <p class="text-black">24/08/2022</p>

                            <p class="mb-0 text-black font-weight-bold">
                              <a
                                class="text-danger"
                                data-toggle="modal"
                                data-target="#delete-address-modal"
                                href="#"
                                ><i class="icofont-ui-delete"></i> DETALHES</a
                              >
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="bg-white card payments-item shadow-sm">
                      <div class="gold-members p-4">
                        <a href="#"> </a>
                        <div class="media">
                          <div class="media-body">
                            <h6 class="mb-1">CANCELADO</h6>
                            <p class="text-black">24/08/2022</p>

                            <p class="mb-0 text-black font-weight-bold">
                              <a
                                class="text-danger"
                                data-toggle="modal"
                                data-target="#delete-address-modal"
                                href="#"
                                ><i class="icofont-ui-delete"></i> DETALHES</a
                              >
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <add-plano v-show="create" :create="activeCreate"></add-plano>
  </div> -->
  <Container>
    <div class="d-flex flex-wrap pagamento text-center align-items-center p-4">
      <h1 class="fw-bold">Meus Pagamentos</h1>
      <!-- 
      <div class="ml-auto">
        <button
          class="
            m-1
            bg-primary
            text-white text-uppercase
            fw-bold
            rounded
            pad-header
          "
          data-bs-toggle="offcanvas"
          data-bs-target="#canvasuser"
          aria-controls="offcanvasRight"
        >
          ADQUIRIR PLANO
        </button>

        <span class="ms-4 fs-4 fw-bold text-capitalize">status:</span>
        <select class="rounded pad-header m-1">
          <option value="">Todos</option>
          <option value="">Pendente</option>
          <option value="">Pagos</option>
          <option value="">Cancelado</option>
        </select>
      </div> -->
    </div>
    <div class="table_section bg-white">
      <table>
        <tr>
          <td>Plano</td>
          <td>Valor</td>
          <td>Referencia</td>
          <td>Vencimento</td>
          <td>STATUS</td>
          <td>AÇÕES</td>
        </tr>
        <tbody>
          <tr v-for="(pagamento, index) in pagamentos" :key="index">
            <td>{{ pagamento.plano }}</td>
            <td>{{ pagamento.valor }}</td>
            <td>{{ pagamento.observacao }}</td>
            <td>{{ pagamento.data_vencimento }}</td>
            <td>{{ pagamento.status_pagamento | filter}}</td>
            <td>
              <a
                :href="pagamento.url_pagamento"
                target="_blank"
                v-if="pagamento.status_pagamento === 'PENDING' || pagamento.status_pagamento === 'OVERDUE'"
                >Emitir pagamento</a
              >
              <a :href="pagamento.url_comprovante_asas" target="_blank" v-else
                >Emitir comprovante</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ADDPLANOADMIN />
  </Container>
</template>
<script>
import addPlano from "@/components/admin/acao/ADDPLANOADMIN.vue";
import Container from "../GLOBALS/container.vue";
import ADDPLANOADMIN from "@/components/admin/acao/ADDPLANOADMIN.vue";
import Api from "@/services/api";

export default {
  name: "pagamentoAdm",
  components: { addPlano, Container, ADDPLANOADMIN },
  data() {
    return {
      create: false,
      pagamentos: [],
      obj: {
        plano: null,
        valor: null,
        status_pagamento: null,
        created_at: null,
        id: null,
        url_pagamento: null,
      },
    };
  },
  mounted() {
    this.chamarPagamentos();
  },

  methods: {
    chamarPagamentos() {
      Api.post("/ver_pagamentos/ZmlsYWRlYXRlbmRpbWVudG8=", {
        user_id: localStorage.getItem("@USER_ID"),
      })
        .then((response) => {
          const data = response.data;
          this.pagamentos = data.pagamento;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  filters: {
    filter(value) {
      if (value === "RECEIVED") {
        return "RECEBIDO";
      }

      if (value === "PENDING") {
        return "PENDENTE";
      }

      if (value === "OVERDUE") {
        return "ATRASADO";
      }

      return "sem status";
    },
  },
};
</script>
<style scoped>
.pagamento {
  background-color: rgb(240, 240, 240);
}

.pagamento input {
  border: 1px solid #d0d0d0;
}

.table {
  font-family: "Roboto", sans-serif;
}

.pagamento {
  border-bottom: 2px solid #ababab;
}
.pad-header {
  padding: 10px 20px;
}
@media (max-width: 800px) {
  .pagamento {
    justify-content: center;
    gap: 10px;
  }

  .pagamento div {
    margin: 0;
  }
}
@media (max-width: 500px) {
  .pagamento button,
  input {
    width: 100%;
  }
}

.table_section {
  overflow: auto;
  height: auto;
}

table {
  width: 100%;
  margin: auto;
  table-layout: fixed;
  min-width: 1000px;
}
table th {
  position: sticky;
  top: 0;
  background-color: #f6f9fc;
  color: #8493a5;
  font-size: 1.12rem;
}

th,
td {
  border-bottom: 1px solid #dddd;
  padding: 20px;
  text-align: center;
}

td {
  font-size: 1.2rem;
}

tr:hover td {
  cursor: pointer;
  background-color: #f6f9fc;
}

td img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
}

body {
  margin-top: 20px;
  background: #eee;
}
/* My Account */
.payments-item img.mr-3 {
  width: 47px;
}
.order-list .btn {
  border-radius: 2px;
  min-width: 121px;
  font-size: 13px;
  padding: 7px 0 7px 0;
}
.osahan-account-page-left .nav-link {
  padding: 18px 20px;
  border: none;
  font-weight: 600;
  color: #535665;
}
.osahan-account-page-left .nav-link i {
  width: 28px;
  height: 28px;
  background: #535665;
  display: inline-block;
  text-align: center;
  line-height: 29px;
  font-size: 15px;
  border-radius: 50px;
  margin: 0 7px 0 0px;
  color: #fff;
}
.osahan-account-page-left .nav-link.active {
  background: #f3f7f8;
  color: #282c3f !important;
}
.osahan-account-page-left .nav-link.active i {
  background: #282c3f !important;
}
.osahan-user-media img {
  width: 90px;
}
.card offer-card h5.card-title {
  border: 2px dotted #000;
}
.card.offer-card h5 {
  border: 1px dotted #daceb7;
  display: inline-table;
  color: #17a2b8;
  margin: 0 0 19px 0;
  font-size: 15px;
  padding: 6px 10px 6px 6px;
  border-radius: 2px;
  background: #fffae6;
  position: relative;
}
.card.offer-card h5 img {
  height: 22px;
  object-fit: cover;
  width: 22px;
  margin: 0 8px 0 0;
  border-radius: 2px;
}
.card.offer-card h5:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #daceb7;
  content: "";
  left: 30px;
  position: absolute;
  bottom: 0;
}
.card.offer-card h5:before {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #daceb7;
  content: "";
  left: 30px;
  position: absolute;
  top: 0;
}
.payments-item .media {
  align-items: center;
}
.payments-item .media img {
  margin: 0 40px 0 11px !important;
}
.reviews-members .media .mr-3 {
  width: 56px;
  height: 56px;
  object-fit: cover;
}
.order-list img.mr-4 {
  width: 70px;
  height: 70px;
  object-fit: cover;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border-radius: 2px;
}
.osahan-cart-item p.text-gray.float-right {
  margin: 3px 0 0 0;
  font-size: 12px;
}
.osahan-cart-item .food-item {
  vertical-align: bottom;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000000;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}
a:hover {
  text-decoration: none;
}
</style>
