
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';



const firebaseConfig = {
  apiKey: "AIzaSyCBhytK2I428dT_hhe2EP4Yv5Md_rsTuzs",
  authDomain: "inzapbrasil.firebaseapp.com",
  databaseURL: "https://inzapbrasil-default-rtdb.firebaseio.com",
  projectId: "inzapbrasil",
  storageBucket: "inzapbrasil.appspot.com",
  messagingSenderId: "1039896318348",
  appId: "1:1039896318348:web:9bd419cdf02f5a9ef706a9"
   // measurementId: "G-GN9MCY4S8D"
};


const firebaseApp = firebase.initializeApp(firebaseConfig);



export default function install(Vue) {
    Object.defineProperty(Vue.prototype, '$firebase', {
        get() {
            return firebaseApp
        }
    })
}