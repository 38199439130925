<template>
  <Container class="mt-5">
    <div class="d-flex flex-column align-items-center">
      <h1 class="fw-bold">Contatos</h1>
    </div>

    <div class="botoes container d-flex flex-wrap justify-content-center gap-3">
      <button class="btn btn-primary" style="padding: 10px 20px;flex-basis: 20%;">Ativos {{ ativos }}</button>
      <button class="btn btn-danger" style="padding: 10px 20px;flex-basis: 20%;">
        Bloqueados {{ bloqueados }}
      </button>
      <button class="btn btn-success" style="padding: 10px 20px;flex-basis: 20%;">
        Com Zap {{ comZap }}
      </button>
      <button class="btn btn-primary" style="padding: 10px 20px;flex-basis: 20%;">
        Sem Zap {{ semZap }}
      </button>
    </div>
    <div class="d-flex flex-wrap usuarios text-center p-1">
      <div class="col-12 mb-5">
        <input
          type="text"
          class="buscar rounded pad-header m-1 w-50"
          placeholder="Busque por nome ou número..."
          aria-label="Recipient's username"
          aria-describedby="button-addon2"
          v-model="pesquisa"
          @keyup="Pesquisar"
        />
        <button
          class="m-1 bg-primary text-white text-uppercase fw-bold rounded pad-header"
          id="button-addon2"
          @click="Pesquisar"
        >
          <i class="fa fa-search"></i>
        </button>

        <button
          class="m-1 bg-success text-white text-uppercase fw-bold rounded pad-header"
          data-bs-toggle="offcanvas"
          data-bs-target="#add_user"
        >
          Cadastrar Contato
        </button>
      </div>
    </div>

    <div class="table-responsive mt-5">
      <div class="mt-2 d-flex flex-column align-items-center gap-2">
        <nav aria-label="Page navigation example">
          <ul class="pagination text-center">
            <li class="page-item">
              <button
                class="page-link"
                aria-label="Previous"
                @click="decrementar()"
              >
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>

            <li
              class="page-item"
              v-for="(list, index) in list_page"
              :key="index"
            >
              <button
                v-show="
                  list.label !== '&laquo; Previous' &&
                  list.label !== 'Next &raquo;'
                "
                @click="SelecionarPagina(list.label)"
                class="page-link"
                :class="{ active: list.active }"
                href="#"
              >
                {{ list.label }}
              </button>
            </li>

            <li class="page-item">
              <button
                class="page-link"
                href="#"
                aria-label="Next"
                @click="incrementar()"
              >
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <table class="table table-striped table-hover mb-4">
        <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Telefone</th>

            <th scope="col">Ação</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(contato, index) in contatos" :key="index">
            <td>{{ contato.nome }}<br>
            {{ contato.banco }} 
            </br>
             {{ contato.agencia_nome }}
          </td>
            <td>{{ contato.fone }}
            <br>
            <span v-if="contato.ativo == 1"> Ativo</span>
            <span v-if="contato.ativo == 0"> Bloqueado</span>
            <br>
            <span v-if="contato.temzap == 1" class="text-warning"> Tem WhatsApp</span>
            <span v-if="contato.temzap == 0"> WhatsApp incorreto</span>
            </td>

            <td
              class="d-flex gap-3 flex-wrap"
              style="align-items: center; justify-content: center"
            >
              <div class="row">
                <div class="col-md-6">
                  <button
                    title="Editar"
                    class="btn btn-primary fs-3"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#acao_editar"
                    @click="Selecionar(contato)"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                </div>
                <div class="col-md-6">
                  <button
                    title="Excluir"
                    class="btn btn-danger fs-3"
                    @click="Deleta(contato.id)"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="mt-5 d-flex flex-column align-items-center gap-2">
        <nav aria-label="Page navigation example">
          <ul class="pagination text-center">
            <li class="page-item">
              <button
                class="page-link"
                aria-label="Previous"
                @click="decrementar()"
              >
                <span aria-hidden="true">&laquo;</span>
              </button>
            </li>

            <li
              class="page-item"
              v-for="(list, index) in list_page"
              :key="index"
            >
              <button
                v-show="
                  list.label !== '&laquo; Previous' &&
                  list.label !== 'Next &raquo;'
                "
                @click="SelecionarPagina(list.label)"
                class="page-link"
                :class="{ active: list.active }"
                href="#"
              >
                {{ list.label }}
              </button>
            </li>

            <li class="page-item">
              <button
                class="page-link"
                href="#"
                aria-label="Next"
                @click="incrementar()"
              >
                <span aria-hidden="true">&raquo;</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <EDITARUSUARIO
      :user="selecionado"
      :Atualiza="listarContatos"
      :grupos="grupos"
    />
    <ADDUSUARIOVUE :Atualiza="listarContatos" :grupos="grupos" />
  </Container>
</template>

<script>
import Container from "../GLOBALS/container.vue";
import EDITARUSUARIO from "./acao/EDITARUSUARIO.vue";
import ADDUSUARIOVUE from "./acao/ADDUSUARIOVUE.vue";
import Swal from "sweetalert2";
import Api from "@/services/api";

export default {
  name: "usuariosAdm",
  components: { Container, EDITARUSUARIO, ADDUSUARIOVUE },
  data() {
    return {
      contatos: [],
      list_page: [],
      page_active: 1,
      selecionado: {},
      pesquisa: "",
      grupos: [],
      ativos: null,
      bloqueados: null,
      comZap: null,
      semZap: null,
    };
  },
  created() {
    this.listarContatos();
    this.chamaGrupo();
  },
  methods: {
    incrementar() {
      if (this.page_active >= this.last_page) return;

      this.page_active++;
    },
    decrementar() {
      if (this.page_active <= 0) return;

      this.page_active--;
    },
    Selecionar(user) {
      this.selecionado = user;
      // console.log(user)
    },
    SelecionarPagina(id) {
      this.page_active = id;
    },
    chamaGrupo() {
      Api.post("/grupos/ZmlsYWRlYXRlbmRpbWVudG8=", {
        id: localStorage.getItem("@USER_ID"),
      })
        .then((response) => {
          this.grupos = response.data.grupos;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    Deleta(id) {
      Swal.fire({
        title: "DESEJA DELETAR?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, deletar!",
      }).then((result) => {
        if (result.isConfirmed) {
          Api.post(`/contatos_exclui/ZmlsYWRlYXRlbmRpbWVudG8=`, {
            id: localStorage.getItem("@USER_ID"),
            contato_id: id,
          })
            .then((response) => {
              if (response.data.status) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "DELETADO COM SUCESSO!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.listarContatos();
                this.pesquisa = "";
              } else {
                alert("Erro ao deletar usuario");
              }
            })
            .catch((erro) => console.log(erro));
        }
      });
    },
    limparPesquisa() {
      if (this.pesquisa == "") {
        this.listarContatos();
      }
    },
    async Pesquisar() {
      Api.post(`/busca_contatos/ZmlsYWRlYXRlbmRpbWVudG8=`, {
        id: localStorage.getItem("@USER_ID"),
        busca: this.pesquisa,
      })
        .then((response) => {
          let data = response.data.contatos.data;
          let links = response.data.contatos.links;
          this.contatos = data;
          this.list_page = links;
        })
        .catch((erro) => console.log(erro));
    },

    async listarContatos() {
      Api.post(`contatos/ZmlsYWRlYXRlbmRpbWVudG8=?page=${this.page_active}`, {
        id: localStorage.getItem("@USER_ID"),
      })
        .then((response) => {
          let data = response.data.contatos.data;
          let links = response.data.contatos.links;
          this.contatos = data;
          this.list_page = links;
          
          this.ativos = response.data.ativos;
          this.bloqueados = response.data.bloqueados;
          this.comZap = response.data.comwhatsapp;
          this.semZap = response.data.semwhatsapp;
          console.log('fala')
        })
        .catch((erro) => console.log(erro));
    },
  },
  filters: {
    nivelFilter(value) {
      switch (value) {
        case 1:
          return `<span class="text-primary fw-bold">Administrador</span>`;
        case 2:
          return `<span class="text-warning fw-bold">Atendente</span>`;
        default:
          return `<span class="text-secondary fw-bold">Usuario</span>`;
      }
    },
  },
  watch: {
    page_active: function () {
      this.listarContatos();
    },
  },
};
</script>

<style scoped>
.pad-header {
  padding: 10px;
}

.usuarios input {
  border: 1px solid #d0d0d0;
}

.table {
  font-family: "Roboto", sans-serif;
}

.usuarios {
  border-bottom: 2px solid #ababab;
}

@media (max-width: 645px) {
  .usuarios {
    justify-content: center;
    gap: 10px;
  }

  .usuarios div {
    margin: 0;
  }
}
@media (max-width: 430px) {
  .usuarios button,
  input {
    width: 100%;
  }
}

.table_section {
  overflow: auto;
  height: auto;
}

table {
  width: 100%;
  margin: auto;
  table-layout: fixed;
  min-width: 1000px;
}
table th {
  position: sticky;
  top: 0;
  background-color: #f6f9fc;
  color: #8493a5;
  font-size: 1.12rem;
}

th,
td {
  border-bottom: 1px solid #dddd;
  padding: 10px 20px;
  word-break: break-all;
  text-align: center;
}

td {
  font-size: 1.2rem;
}

tr:hover td {
  cursor: pointer;
  background-color: #f6f9fc;
}

td img {
  height: 60px;
  width: 60px;
  object-fit: cover;
  border-radius: 50%;
}
</style>
