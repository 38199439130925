var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Container',{staticClass:"bg-cinza p-5",attrs:{"nome":_vm.nome}},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('h1',{staticClass:"fw-bold"},[_vm._v("Meus Canais")])]),_c('div',{staticClass:"grid text-center mt-2 gap-4"},_vm._l((_vm.linhas),function(linha,index){return _c('div',{key:index,staticClass:"bg-white p-5 d-flex flex-column rounded mt-3"},[_c('h1',{staticClass:"text-uppercase text-center fs-3"},[_vm._v(" Celular - "+_vm._s(linha.number)+" ")]),(linha.state === 'Connected' || linha.state === 'inChat')?_c('div',[_c('img',{attrs:{"src":_vm.objImg.success,"width":"180px","alt":""}}),_c('button',{staticClass:"btn btn-primary mt-5 btn-pad w-100 fw-bold",on:{"click":function($event){return _vm.desconectar()}}},[(_vm.status === 0)?_c('span',[_vm._v("DESCONECTAR")]):_vm._e(),(_vm.status === 1)?_c('span',[_vm._v("Processando...")]):_vm._e(),(_vm.status === 2)?_c('span',[_vm._v("Desconectado com sucesso!")]):_vm._e()]),_c('button',{staticClass:"btn btn-warning mt-5 text-uppercase btn-pad w-100 fw-bold",on:{"click":function($event){return _vm.importa_contatos()}}},[(_vm.status === 0)?_c('span',[_vm._v("Importar Contatos")]):_vm._e(),(_vm.status === 3)?_c('span',[_vm._v("Importando...")]):_vm._e(),(_vm.status === 4)?_c('span',[_vm._v(_vm._s(_vm.qtd_contatos)+" Contatos importados com sucesso!")]):_vm._e()])]):_vm._e(),(
          linha.state === 'autocloseCalled' ||
          linha.state === 'STARTING' ||
          linha.state === 'UNPAIRED' ||
          linha.state === 'notLogged' ||
          linha.state === 'qrReadError' ||
          linha.state === 'browserClose'  
          
        )?_c('div',[_c('img',{attrs:{"src":linha.qrcode,"alt":""}}),_c('button',{staticClass:"btn btn-primary mt-5 btn-pad w-100 fw-bold",on:{"click":function($event){return _vm.gerarQrcode()}}},[(_vm.status === 0)?_c('span',[_vm._v("ATUALIZAR QRCODE")]):_vm._e(),(_vm.status === 5)?_c('span',[_vm._v("Atualizando...")]):_vm._e(),(_vm.status === 6)?_c('span',[_vm._v("QRCODE atualizado com sucesso!")]):_vm._e()])]):_vm._e(),(linha.state === 'Disconnected')?_c('div',[_c('img',{attrs:{"src":_vm.objImg.error,"width":"150px","alt":""}}),_c('button',{staticClass:"btn btn-primary mt-5 btn-pad w-100 fw-bold",on:{"click":function($event){return _vm.gerarQrcode()}}},[(_vm.status === 0)?_c('span',[_vm._v("GERAR QRCODE")]):_vm._e(),(_vm.status === 5)?_c('span',[_vm._v("Atualizando...")]):_vm._e(),(_vm.status === 6)?_c('span',[_vm._v("QRCODE Gerado com sucesso!")]):_vm._e()])]):_vm._e(),(linha.state === 'logout')?_c('div',[_c('img',{attrs:{"src":_vm.objImg.error,"alt":""}}),_c('button',{staticClass:"btn btn-primary mt-5 btn-pad w-100 fw-bold",on:{"click":function($event){return _vm.gerarQrcode()}}},[(_vm.status === 0)?_c('span',[_vm._v("GERAR QRCODE")]):_vm._e(),(_vm.status === 5)?_c('span',[_vm._v("Atualizando...")]):_vm._e(),(_vm.status === 2)?_c('span',[_vm._v("GERAR QRCODE!")]):_vm._e()])]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }