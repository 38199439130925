<template>
  <Container>
    <div
      class="offcanvas offcanvas-end"
      style="width: 85%"
      tabindex="-1"
      id="acao_editar"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <div class="col-md-6 text-center mb-4">
          <h2 class="offcanvas-title fs-3" id="offcanvasRightLabel">
            <b> Editar Usuário</b>
          </h2>
        </div>

        <button
          type="button"
          class="btn btn-danger ms-auto"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          Fechar
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="container-fluid row">
          <form class="row">
            <div class="col-md-4 form-group">
              <label class="col-form-label fs-3 mb-2">Nome</label>
              <input
                type="text"
                class="form-control form-pad"
                v-model="obj.nome"
              />
            </div>

            <div class="col-md-4 form-group">
              <label class="col-form-label fs-3 mb-2">Telefone</label>

              <input
                type="text"
                class="form-control form-pad"
                v-model="obj.fone"
              />
            </div>

            <div class="col-md-4 form-group">
              <label class="col-form-label fs-3 mb-2">Email</label>
              
                <input
                  type="email"
                  class="form-control form-pad"
                  v-model="obj.email"
                />
            </div>

            <div class="col-md-4 mt-3 form-group">
              <label class="col-form-label fs-3 mb-2">Gênero</label>

              <select class="form-select form-pad" v-model="obj.genero">
                <option value="0" selected>Selecione</option>
                <option value="Masculino">MASCULINO</option>
                <option value="Feminino">FEMININO</option>
                <option value="LGBTQIA+">LGBTQIA+</option>
              </select>
            </div>

            <div class="col-md-4 mt-3 form-group">
              <label class="col-form-label fs-3 mb-2">Nascimento</label>
              <input
                type="text"
                class="form-control form-pad"
                v-mask="mascara.nascimento"
                v-model="nascimento"
              />
            </div>

            <div class="col-md-4 form-group mt-3">
              <label class="col-form-label fs-3 mb-2">Grupo</label>

              <select v-model="obj.grupo_id" class="form-select form-pad">
                <option value="0" disabled>Selecione o grupo</option>
                <option
                  v-for="grupo in grupos"
                  :value="grupo.id"
                  :key="grupo.id"
                >
                  {{ grupo.nome_grupo }}
                </option>
              </select>
            </div>
            <Locais
              :Atualizar="AtualizaLocal"
              :cidadein="this.obj.cidade"
              :estadoin="this.obj.uf"
              :user="user.id"
            />

            <div class="col-md-12 form-group mt-2">
              <label class="col-form-label fs-3 mb-2">Observacao</label>
              <div class="">
                <textarea
                  class="form-control"
                  cols="2"
                  rows="2"
                  v-model="obj.observacao"
                ></textarea>
              </div>
            </div>
            <div class="col-md-2 mt-3">
              <button
                type="button"
                class="btn btn-primary btn-block form-pad"
                @click="Salvar_edicao"
              >
                EDITAR
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import Container from "@/components/GLOBALS/container.vue";
import Locais from "@/components/GLOBALS/CidadesEstado.vue";
import Api from "@/services/api";
import Swal from "sweetalert2";

export default {
  name: "editarContato",
  components: { Container, Locais },

  props: {
    grupos: {
      type: Array,
    },
    user: {
      type: Object,
    },
    AtualizaFrente: { type: Function },
  },
  data() {
    return {
      id: "",
      nascimento: "",
      obj: {
        id: localStorage.getItem("@USER_ID"),
        nome: "",
        contato_id: "",
        fone: "",
        genero: "0",
        dia_aniversario: "",
        mes_aniversario: "",
        ano_aniversario: "",
        observacao: "",
        email: "",
        cidade: 0,
        uf: 0,
        grupo_id: 0,
      },

      mascara: {
        nascimento: "##/##/####",
      },
    };
  },
  methods: {
    AtualizaLocal(uf, cidade) {
      this.obj.uf = uf;
      this.obj.cidade = cidade;
    },
    GetUser() {
      this.nascimento;
      this.obj.nome = this.user.nome;
      this.obj.fone = this.user.fone;
      this.obj.contato_id = this.user.id;
      this.obj.genero = this.user.genero;
      this.nascimento = `${this.user.dia_aniversario}/${this.user.mes_aniversario}/${this.user.ano_aniversario}`;
      this.obj.observacao = this.user.observacao;
      this.obj.email = this.user.email;
      this.obj.grupo_id = this.user.grupo_id;
      this.obj.cidade = parseInt(this.user.cidade);
      this.obj.uf = parseInt(this.user.uf);
    },
    Salvar_edicao() {
      Api.post(
        `/contatos_update/ZmlsYWRlYXRlbmRpbWVudG8=`,

        this.obj
      )
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: response.data.mensagem,
          });
          this.AtualizaFrente();
          const truck_modal = document.querySelector("#acao_editar");
          const modal = bootstrap.Offcanvas.getOrCreateInstance(truck_modal);
          modal.hide();
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
          });
        });
      this.btn = true;
    },
  },

  mounted() {
    this.GetUser();
  },
  watch: {
    user: function () {
      this.GetUser();
      this.btn = true;
    },
    nascimento: function () {
      this.obj.dia_aniversario = this.nascimento.substring(0, 2);
      this.obj.mes_aniversario = this.nascimento.substring(3, 5);
      this.obj.ano_aniversario = this.nascimento.substring(6, 11);
    },
  },
};
</script>

<style>
.form-pad {
  padding: 10px 20px !important;
}

@media (max-width: 600px) {
  button {
    width: 100%;
  }
}
</style>
