<template>
  <Container>
    <div
      class="offcanvas offcanvas-end"
      style="width: 80%"
      tabindex="-1"
      id="add_user"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <button
          type="button"
          class="btn btn-danger ms-auto"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          Fechar
        </button>
      </div>
      <div class="offcanvas-body">
        <div class="container-fluid row">
          <div class="col-md-12 text-center mb-3">
            <h2 class="offcanvas-title fs-3" id="offcanvasRightLabel">
             <b> Novo Contato</b>
            </h2>
          </div>

          <form class="row container">
            <div class="col-md-4 form-group">
              <label class="col-form-label fs-3 ">Nome</label>
              <input
                type="text"
                class="form-control form-pad"
                v-model="obj.nome"
              />
            </div>

            <div class="col-md-4 form-group">
              <label class="col-form-label fs-3 ">Telefone</label>

              <input
                type="text"
                class="form-control form-pad"
                v-model="obj.fone"
                v-mask="mascara.cel"
              />
            </div>

            <div class="col-md-4 form-group ">
              <label class="col-form-label fs-3 ">Email</label>
              <div class="">
                <input
                  type="email"
                  class="form-control form-pad"
                  v-model="obj.email"
                />
              </div>
            </div>

            <div class="col-md-2 mt-3 form-group">
              <label class="col-form-label fs-3 ">Gênero</label>

              <select class="form-select form-pad" v-model="obj.genero">
                <option value="0" selected>Selecione</option>
                <option value="Masculino">MASCULINO</option>
                <option value="Feminino">FEMININO</option>
                <option value="LGBTQIA+">LGBTQIA+</option>
              </select>
            </div>

            <div class="col-md-3 mt-3 form-group">
              <label class="col-form-label fs-3 ">Nascimento</label>
              <input
                type="text"
                class="form-control form-pad"
                v-mask="mascara.nascimento"
                v-model="obj.nascimento"
              />
            </div>
            <div class="col-md-2 form-group mt-3">
              <label class="fs-3  col-form-label">Estado</label>
              <select
                class="form-select form-pad"
                v-model="obj_city.uf_id"
                @change="mudarCidades"
              >
                <option value="0">Selecione o estado</option>
                <option
                  v-for="estados in estado"
                  :value="estados.id"
                  :key="estados.id"
                >
                  {{ estados.sigla }}
                </option>
              </select>
            </div>
            <div class="col-md-2 form-group mt-3">
              <label class="col-form-label fs-3 mb-">Cidade</label>

              <select class="form-select form-pad">
                <option value="0" disabled>Selecione a cidade</option>
                <option
                  v-for="cidades in cidade"
                  :value="cidades.id"
                  :key="cidades.id"
                >
                  {{ cidades.nome }}
                </option>
              </select>
            </div>
            <div class="col-md-3 form-group mt-3">
              <label class="col-form-label fs-3 ">Grupo</label>

              <select class="form-select form-pad">
                <option value="0" disabled>Selecione o grupo</option>
                <option
                  v-for="grupo in grupos"
                  :value="grupo.id"
                  :key="grupo.id"
                >
                  {{ grupo.nome_grupo }}
                </option>
              </select>
            </div>

            <div class="col-md-12 form-group mt-2">
              <label class="col-form-label fs-3 mb-2">Observacao</label>
              <div class="">
                <textarea
                  class="form-control"
                  cols="5"
                  rows="5"
                  v-model="obj.observacao"
                ></textarea>
              </div>
            </div>
            <div class="col-md-2 mt-3 center" >
              <button 
                type="button"
                class="btn btn-primary form-pad fw-bold"
                @click="CriarUsuario"
              >
                CADASTRAR
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </Container>
  <!-- <Container>
    <div
      class="offcanvas offcanvas-end"
      style="width: 100%"
      tabindex="-1"
      id="add_user"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header">
        <h1 class="offcanvas-title" id="offcanvasRightLabel">
          Adicionar CONTATO
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <div class="row mb-3">
          <label class="col-sm-2 col-form-label">Nome</label>
          <div class="col-sm-10">
            <input type="text" class="form-control" v-model="obj.nome" />
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-sm-2 col-form-label">Celular</label>
          <div class="col-sm-10">
            <input type="email" class="form-control" v-model="obj.fone" />
          </div>
        </div>
        <button
          type="button"
          class="btn btn-primary"
          @click="CriarUsuario"
        >
          CADASTRAR
        </button>
      </div>
    </div>
  </Container>  -->
</template>

<script>
import Container from "@/components/GLOBALS/container.vue";
import Api from "@/services/api";
import Swal from "sweetalert2";

export default {
  name: "AddUser",
  components: { Container },
  data() {
    return {
      mascara: {
        nascimento: "##/##/####",
        cel: "(##)#########",
      },
      obj: {
        id: localStorage.getItem("@USER_ID"),
        nome: "",
        contato_id: "",
        fone: "",
        genero: "0",
        nascimento: "",
        observacao: "",
        email: "",
        cidade: "",
        uf: "",
      },
      cidade: [],
      estado: [],
      obj_city: {
        id: localStorage.getItem("@USER_ID"),
        uf_id: 10,
      },
    };
  },
  mounted() {
    this.RenderEstados();
    this.RenderCidades();
  },
  methods: {
    mudarCidades() {
      this.RenderCidades();
    },
    RenderEstados() {
      Api.post(`/cidades/ZmlsYWRlYXRlbmRpbWVudG8=`, this.obj_city)
        .then((response) => {
          this.estado = response.data.estados;

        })
        .catch((err) => {
          console.log(err);
        });
    },
    RenderCidades() {
      Api.post(`/cidades/ZmlsYWRlYXRlbmRpbWVudG8=`, this.obj_city)
        .then((response) => {
          this.cidade = response.data.cidades;

        })
        .catch((err) => {
          console.log(err);
        });
    },
    CriarUsuario() {
      Api.post("/contatos_grava/ZmlsYWRlYXRlbmRpbWVudG8=", this.obj)
        .then((response) => {
          
         // console.log(response.data)
          Swal.fire({
            icon: "success",
            title: response.data.mensagem,
          });
         // this.Atualiza();
          this.obj = {};
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
          });
        });
    },
  },
  props: {
      grupos: {
        type: Array,
      },
    }
};
</script>

