<template>
  <section class="mt-2 mb-5" id="planos">
    <h1
      class="text-center bg-laranja text-light fw-bold p-3 font-oswald"
      style="font-size: 2.5rem"
    >
      Escolha o INZUPT do seu jeito
    </h1>

    <div class="container mt-5">
      <div class="precos">
        <div
          :class="dados.destaque ? 'destaque' : ''"
          class="preco p-3 sombra"
          v-for="dados in data"
          :key="dados.id"
        >
          <div class="header">
            <h1 class="text-justify fw-bold fs-3">
              Plano <br />
              {{ dados.nome }}
            </h1>
          </div>
          <div class="conteudo">
            <p class="text-center">
              {{ dados.conteudo }}
            </p>
            <p>Planos à partir de</p>
            <h2 class="valor">
              {{ dados.valor_inicial | brMoneyFilter }}
            </h2>
            <button
              class="botao-personalizado mt-3 fw-bold text-white"
              @click="mostrarPlanos(dados.id)"
            >
              Selecione
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-10" id="planosPrecos">
      <h1 v-show="situacao" class="text-center mb-5 font-oswald">
        Plano {{ planoNome }}
      </h1>
      <BannerPlanosPrice
        v-show="situacao"
        :planos="planos"
        :planoNome="planoNome"
      />
    </div>
  </section>
</template>




<script>
import BannerPlanosPrice from "./BannerPlanosPrice.vue";
import Api from "@/services/api";

export default {
  name: "BannerPlanos",
  components: { BannerPlanosPrice },
  data() {
    return {
      situacao: false,
      planos: [],
      planoNome: "",
      data: [],
    };
  },
  mounted() {
    Api.get("/planos")
      .then((response) => {
        this.data = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    scroolAnimation(element) {
      const to = document.querySelector(`${element}`).offsetTop;

      window.scroll({
        top: to - 150,
        behavior: "smooth",
      });
    },
    mostrarPlanos(id) {
      Api.get(`/subplanos/${id}`)
        .then((response) => {
          if (this.isEmpty(response.data)) {
            this.situacao = false;
            return;
          } else {
            this.planoNome = this.data[id - 1].nome;

            const arrayPlanos = response.data;

            this.planos = arrayPlanos.filter(
              (plano) => typeof plano === "object"
            );

            this.situacao = false;

            this.situacao = !this.situacao;

            this.scroolAnimation("#planosPrecos");
          }
        })
        .catch((error) => {
          console.log("erro na api => " + error);
        });
    },
  },
  filters: {
    brMoneyFilter(value) {
      if (value === 0) return "Consulte-nos";

      return value.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }) + '/ mês';
    },
  },
};
</script>






<style scoped>
.mt-10 {
  margin-top: 200px;
}

.sombra {
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);
}

.botao-personalizado {
  padding: 12px 30px;
  background-color: #ce5800;
  font-size: 1.2rem;
  border-radius: 30px;
}

.verde {
  color: #36b37e;
}

.precos {
  margin: auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
}

.preco {
  height: 400px;
  padding: 4px;
}
.preco,
.conteudo {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}

.destaque {
  background-color: #f58634;
}

.destaque h1,
.destaque p,
.destaque .valor {
  color: #fff;
}

.plano:nth-child(2) {
  border: 2px solid #f58634;
}

button {
  font-family: "Fira Sans", sans-serif;
}

@media (max-width: 1000px) {
  .planos {
    gap: 20px;
    grid-template-columns: 1fr;
  }
}

.bg-azul {
  background-color: #00308f;
}

@media (max-width: 600px) {
  .precos {
    grid-template-columns: 1fr;
  }
}
</style>