<template>
  <div class="box">
    <front-menu />
    <div class="content">
      <div
        class="container bg-white text-center info"
        v-if="!isRegisterConsulteNos"
      >
        <div class="plano">
          <h1>Plano {{ dados.nome }} {{ planValue | money }}</h1>
          <h3 class="text-success" v-show="pay">
            Cadastro realizado com sucesso efetue o pagamento
          </h3>
        </div>

        <banner-register
          :dados="dados"
          :setTokenCad="setTokenCad"
          :pay="pay"
          :setClienteId="setClienteId"
        />
        <BannerPagamento :dados="dados" :pay="pay" :clienteId="clienteId" />
      </div>
      <div class="container bg-white text-center info" v-else>
        <div class="plano">
          <h1>Deixe seus dados e entraremos em contato Ou fale conosco via zap</h1>
        </div>

        <banner-register-consulte-nos />

        <div class="d-flex align-items-center justify-content-center">
          <a
            href="https://api.whatsapp.com/send?phone=556541410643&text=Acessei%20o%20site%20inzapbrasil.com.br/index.html%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%A3o"
            class="btn-entrarContato"
          >
            <img src="../assets/whatsapp.png" alt="" />Fale conosco via zap
          </a>
        </div>
      </div>
    </div>
    <banner-footer />
  </div>
</template>

<script>
import FrontMenu from "@/components/GLOBALS/FrontMenu.vue";
import BannerRegister from "@/components/register/BannerRegister.vue";
import BannerRegisterConsulteNos from "@/components/register/BannerRegisterConsulteNos.vue";
import BannerPagamento from "@/components/register/BannerPagamento.vue";
import BannerFooter from "@/components/index/BannerFooter.vue";
import router from "@/router";
import Api from "@/services/api";

export default {
  name: "RegisterPage",
  components: {
    FrontMenu,
    BannerRegister,
    BannerFooter,
    BannerPagamento,
    BannerRegisterConsulteNos,
  },
  data() {
    return {
      isRegisterConsulteNos: false,
      pay: false, //false
      dados: {},
      clienteId: null,
      planValue: 0,
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.listarSubPlano();
    this.verifyTokenCad();

    this.isRegisterConsulteNos =
      +this.$route.query.registerSituation === 0 ? true : false;
  },
  methods: {
    listarSubPlano() {
      Api.get("subplanos/" + this.$route.query.id)
        .then((response) => {
         
          this.dados = response.data[this.$route.query.posicao];
         
          this.planValue = this.dados.valor;
        })
        .catch(() => {
          router.push({ name: "index" });
        });
    },
    verifyTokenCad() {
      let tk = localStorage.getItem("@CAD");
      if (tk) {
        this.pay = true;
      } else {
        this.pay = false;
      }
    },
    setTokenCad(token) {
      localStorage.setItem("@CAD", token);
      this.verifyTokenCad();
    },

    setClienteId(id) {

      this.clienteId = id;
    },
  },
  filters: {
    money(value) {
      return value.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
  },
};
</script>

<style scoped>
.content {
  padding-top: 100px;
  background-image: linear-gradient(to right, #7ed0dd, #479fcf);
}
.box {
  background-image: linear-gradient(to right, #7ed0dd, #479fcf);
}
.container-form {
  max-width: 800px;
  width: 90%;
}

.plano {
  grid-column: span 2;
}

.info {
  border-radius: 10px;
  max-width: 90%;
  margin: auto;
  display: grid;
  place-content: center;
  padding: 30px 0;
}

@media (max-width: 760px) {

  .container-form {
  max-width: 800px;
  width: 100%;
}
  .info {
    display: grid;
    place-items: center;
    max-width: 100%;
    padding: 0px 0;
 
  }
  .plano {
    grid-column: 1;
  }
  .content {
    padding-top: 140px;
  }
}

.btn-entrarContato {
  background: transparent;
  border: 2px solid #29a71a;
  padding: 20px 25px;

  color: #29a71a;
  font-weight: bold;

  display: flex;

  align-items: center;
  gap: 10px;
  justify-content: space-between;

  font-size: 1.5rem;
  border-radius: 100px;
  transition: 0.3s;
  text-decoration: none;

}

.btn-entrarContato:hover {
  background: #29a71a;
  /* border: 2px solid #29a71a; */
  /* padding: 20px 15px; */

  color: #fff;
  /* font-weight: bold; */

  /* display: flex; */

  /* align-items: center; */
  /* gap: 10px; */
  /* justify-content: space-between; */

  /* font-size: 1.5rem; */
  /* border-radius: 100px; */
}

.btn-entrarContato img {
  border: 3px solid #fff;
  border-radius: 50%;
}

.sombra {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}
</style>
