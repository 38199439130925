<template>
  <div class="col-md-5">
    <div class="row">
      <div class="col-md-4 mt-2">
        <label class="fs-3 mb-2 col-form-label">Estado</label>
        <select
          class="form-select form-pad"
          v-model="obj.uf_id"
          @change="mudarCidades"
        >
          <option value="0">Selecione o estado</option>
          <option
            @change="mudarCidades(estado.id)"
            v-for="estados in estado"
            :value="estados.id"
            :key="estados.id"
          >
            {{ estados.sigla }}
          </option>
        </select>
      </div>

      <div class="col-md-8 mt-1">
        <label class="col-form-label fs-3 mb-2">Cidade </label>

        <select class="form-select form-pad" @change="EscolherCidades($event)">
          <option value="0" disabled>Selecione a cidade</option>
          <option
            v-for="cidades in cidade"
            :value="cidades.id"
            :key="cidades.id"
            :selected="cidades.id === cidadein"
          >
            {{ cidades.nome }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/services/api";
export default {
  name: "CidadeEstado",
  props: {
    user: { type: Number },
    Atualizar: { type: Function },
    cidadein: { type: Number },
    estadoin: { type: Number },
  },
  data() {
    return {
      estado: [],
      cidade: [],
      cidade_escolhida: "",
      obj: {
        id: localStorage.getItem("@USER_ID"),
        uf_id: 10,
      },
    };
  },
  methods: {
    VerificaRetorno() {
      this.obj.uf_id = this.estadoin;
      this.cidade_escolhida = this.cidadein;
    },
    mudarCidades() {
      this.Atualizar(this.obj.uf_id, 10);
      this.RenderCidades();
    },
    EscolherCidades(event) {
      this.cidade_escolhida = parseInt(event.target.value);
      this.Atualizar(this.obj.uf_id, this.cidade_escolhida);
    },
    RenderEstados() {
      Api.post(`/cidades/ZmlsYWRlYXRlbmRpbWVudG8=`, this.obj)
        .then((response) => {
          this.estado = response.data.estados;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    RenderCidades() {
      Api.post(`/cidades/ZmlsYWRlYXRlbmRpbWVudG8=`, this.obj)
        .then((response) => {
          this.cidade = response.data.cidades;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  watch: {
    user: function () {
      this.obj.uf_id = this.estadoin;
      this.RenderEstados();
      this.RenderCidades();
      this.VerificaRetorno();
    },
  },
};
</script>

<style></style>
