var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.status)?_c('div',{directives:[{name:"chat-scroll",rawName:"v-chat-scroll"}],staticClass:"chatBox",class:_vm.estadoResponderMensagem ? 'layoutResponderAlturaTeclado' : ''},[(_vm.abrirBoxOpcoes)?_c('div',{staticClass:"fundo-transparente",on:{"click":_vm.limparBoxOpcoes}}):_vm._e(),(_vm.processando)?_c('div',[_vm._v("Atualizando mensagens... ")]):_vm._e(),_vm._l((_vm.mensagens),function(mensagem,index){return _c('div',{key:index,staticClass:"message",class:{ my_message: mensagem.fone_enviado !== _vm.foneAtendido }},[(_vm.estadoEncaminharMensagens)?_c('label',{staticClass:"conteiner"},[_c('input',{staticClass:"form-check-input selecionarMensagens",attrs:{"type":"checkbox"}}),_c('span',{staticClass:"checkmark",attrs:{"data-id":mensagem.message_id,"data-type":mensagem.type},on:{"click":_vm.marcarMensagem}})]):_vm._e(),_c('div',{staticClass:"box-mensagens"},[(mensagem.type === 'text')?_c('div',{staticClass:"message-normal"},[(mensagem.status == 'DELETED')?_c('span',{staticClass:"cinza"},[_vm._v(" Mensagem apagada")]):_vm._e(),(mensagem.status != 'DELETED')?_c('span',[_vm._v(" "+_vm._s(mensagem.mensagem)+" "),(mensagem.wook == 'onack')?_c('div',{staticClass:"alinhadireita"},[(mensagem.status == 'RECEIVED')?_c('i',{staticClass:"fas fa-check-double cinza"}):_vm._e(),(mensagem.status == 'SENT')?_c('i',{staticClass:"fas fa-check"}):_vm._e(),(mensagem.status == 'READ'  )?_c('i',{staticClass:"fas fa-check-double text-success"}):_vm._e()]):_vm._e(),(!_vm.estadoEncaminharMensagens)?_c('div',{staticClass:"pd-2 cursor-pointer box_abre_selecoes",on:{"click":_vm.abreSelecoes}},[_c('i',{staticClass:"fas fa-chevron-down abrir-selecoes"}),_c('div',{staticClass:"box-opcoes"},_vm._l((_vm.option),function(opcoes,index){return _c('span',{key:index,staticClass:"d-block mb-2",on:{"click":function($event){return _vm.escolhaSelecionado(
                    opcoes.id,
                    mensagem.message_id,
                    mensagem.mensagem
                  )}}},[_vm._v(" "+_vm._s(opcoes.msg)+" ")])}),0)]):_vm._e()]):_vm._e()]):_vm._e(),(mensagem.type === 'vcard')?_c('div',{staticClass:"vcard message-normal"},[(mensagem.status == 'DELETED')?_c('span',{staticClass:"cinza"},[_vm._v(" Mensagem apagada")]):_vm._e(),(mensagem.status != 'DELETED')?_c('span',[_c('div',{staticClass:"vcard-header"},[_c('img',{staticClass:"user_foto",attrs:{"src":require("@/assets/default.png"),"alt":""}}),_vm._v(" "+_vm._s(mensagem.contactName)),_c('br'),_vm._v(" "+_vm._s(mensagem.mensagem)+" "),(mensagem.wook == 'onack')?_c('div',{staticClass:"alinhadireita"},[(mensagem.status == 'RECEIVED')?_c('i',{staticClass:"fas fa-check-double cinza"}):_vm._e(),(mensagem.status == 'SENT')?_c('i',{staticClass:"fas fa-check"}):_vm._e(),(mensagem.status == 'READ'  )?_c('i',{staticClass:"fas fa-check-double text-success"}):_vm._e()]):_vm._e(),(!_vm.estadoEncaminharMensagens)?_c('div',{staticClass:"pd-2 cursor-pointer box_abre_selecoes",on:{"click":_vm.abreSelecoes}},[_c('i',{staticClass:"fas fa-chevron-down abrir-selecoes"}),_c('div',{staticClass:"box-opcoes"},_vm._l((_vm.option),function(opcoes,index){return _c('span',{key:index,staticClass:"d-block mb-2",on:{"click":function($event){return _vm.escolhaSelecionado(
                      opcoes.id,
                      mensagem.message_id,
                      mensagem.mensagem
                    )}}},[_vm._v(" "+_vm._s(opcoes.msg)+" ")])}),0)]):_vm._e()]),_c('div',{staticClass:"vcard-body"},[_c('button',{staticClass:"conversar",on:{"click":function($event){return _vm.abrirConversa(mensagem)}}},[_vm._v("Conversar")])])]):_vm._e()]):_vm._e(),(mensagem.type === 'ptt')?_c('div',{staticClass:"message-normal"},[(mensagem.status == 'DELETED')?_c('span',{staticClass:"cinza"},[_vm._v(" Audio apagado")]):_vm._e(),(mensagem.status != 'DELETED')?_c('span',[_c('audio',{staticStyle:{"max-width":"100%"},attrs:{"controls":"controls"}},[_c('source',{attrs:{"id":"audiobase64","src":mensagem.url_link,"controls":"","autoplay":""}})]),(mensagem.wook == 'onack')?_c('div',{staticClass:"alinhadireita"},[(mensagem.status == 'RECEIVED')?_c('i',{staticClass:"fas fa-check-double cinza"}):_vm._e(),(mensagem.status == 'SENT')?_c('i',{staticClass:"fas fa-check"}):_vm._e(),(mensagem.status == 'READ'  )?_c('i',{staticClass:"fas fa-check-double text-success"}):_vm._e()]):_vm._e(),(!_vm.estadoEncaminharMensagens)?_c('div',{staticClass:"pd-2 cursor-pointer box_abre_selecoes",on:{"click":_vm.abreSelecoes}},[_c('i',{staticClass:"fas fa-chevron-down abrir-selecoes"}),_c('div',{staticClass:"box-opcoes"},_vm._l((_vm.option),function(opcoes,index){return _c('span',{key:index,staticClass:"d-block mb-2",on:{"click":function($event){return _vm.escolhaSelecionado(
                    opcoes.id,
                    mensagem.message_id,
                    mensagem.mensagem
                  )}}},[_vm._v(" "+_vm._s(opcoes.msg)+" ")])}),0)]):_vm._e()]):_vm._e()]):_vm._e(),(mensagem.type === 'sticker')?_c('div',{staticClass:"message-normal"},[(mensagem.status == 'DELETED')?_c('span',{staticClass:"cinza"},[_vm._v(" Mensagem apagada")]):_vm._e(),(mensagem.status != 'DELETED')?_c('span',[_c('img',{staticClass:"img-thumbnail",attrs:{"src":mensagem.url_link}}),(mensagem.wook == 'onack')?_c('div',{staticClass:"alinhadireita"},[(mensagem.status == 'RECEIVED')?_c('i',{staticClass:"fas fa-check-double cinza"}):_vm._e(),(mensagem.status == 'SENT')?_c('i',{staticClass:"fas fa-check"}):_vm._e(),(mensagem.status == 'READ'  )?_c('i',{staticClass:"fas fa-check-double text-success"}):_vm._e()]):_vm._e(),(!_vm.estadoEncaminharMensagens)?_c('div',{staticClass:"pd-2 cursor-pointer box_abre_selecoes",on:{"click":_vm.abreSelecoes}},[_c('i',{staticClass:"fas fa-chevron-down abrir-selecoes"}),_c('div',{staticClass:"box-opcoes"},_vm._l((_vm.option),function(opcoes,index){return _c('span',{key:index,staticClass:"d-block mb-2",on:{"click":function($event){return _vm.escolhaSelecionado(
                    opcoes.id,
                    mensagem.message_id,
                    mensagem.mensagem
                  )}}},[_vm._v(" "+_vm._s(opcoes.msg)+" ")])}),0)]):_vm._e()]):_vm._e()]):_vm._e(),(mensagem.type === 'link')?_c('div',{staticClass:"message-normal"},[(mensagem.status == 'DELETED')?_c('span',{staticClass:"cinza"},[_vm._v(" Mensagem apagada")]):_vm._e(),(mensagem.status != 'DELETED')?_c('span',[_vm._v(" >"+_vm._s(mensagem.mensagem)+" "),(mensagem.wook == 'onack')?_c('div',{staticClass:"alinhadireita"},[(mensagem.status == 'RECEIVED')?_c('i',{staticClass:"fas fa-check-double cinza"}):_vm._e(),(mensagem.status == 'SENT')?_c('i',{staticClass:"fas fa-check"}):_vm._e(),(mensagem.status == 'READ'  )?_c('i',{staticClass:"fas fa-check-double text-success"}):_vm._e()]):_vm._e(),(!_vm.estadoEncaminharMensagens)?_c('div',{staticClass:"pd-2 cursor-pointer box_abre_selecoes",on:{"click":_vm.abreSelecoes}},[_c('i',{staticClass:"fas fa-chevron-down abrir-selecoes"}),_c('div',{staticClass:"box-opcoes"},_vm._l((_vm.option),function(opcoes,index){return _c('span',{key:index,staticClass:"d-block mb-2",on:{"click":function($event){return _vm.escolhaSelecionado(
                    opcoes.id,
                    mensagem.message_id,
                    mensagem.mensagem
                  )}}},[_vm._v(" "+_vm._s(opcoes.msg)+" ")])}),0)]):_vm._e()]):_vm._e()]):_vm._e(),(mensagem.type === 'video')?_c('div',{staticClass:"message-normal"},[(mensagem.status == 'DELETED')?_c('span',{staticClass:"cinza"},[_vm._v(" Vídeo apagado")]):_vm._e(),(mensagem.status != 'DELETED')?_c('span',[_c('video',{attrs:{"width":"50%","controls":""}},[_c('source',{attrs:{"type":"video/mp4","src":mensagem.url_link}})]),(mensagem.wook == 'onack')?_c('div',{staticClass:"alinhadireita"},[(mensagem.status == 'RECEIVED')?_c('i',{staticClass:"fas fa-check-double cinza"}):_vm._e(),(mensagem.status == 'SENT')?_c('i',{staticClass:"fas fa-check"}):_vm._e(),(mensagem.status == 'READ'  )?_c('i',{staticClass:"fas fa-check-double text-success"}):_vm._e()]):_vm._e(),(!_vm.estadoEncaminharMensagens)?_c('div',{staticClass:"pd-2 cursor-pointer box_abre_selecoes",on:{"click":_vm.abreSelecoes}},[_c('i',{staticClass:"fas fa-chevron-down abrir-selecoes"}),_c('div',{staticClass:"box-opcoes"},_vm._l((_vm.option),function(opcoes,index){return _c('span',{key:index,staticClass:"d-block mb-2",on:{"click":function($event){return _vm.escolhaSelecionado(
                    opcoes.id,
                    mensagem.message_id,
                    mensagem.mensagem
                  )}}},[_vm._v(" "+_vm._s(opcoes.msg)+" ")])}),0)]):_vm._e()]):_vm._e()]):_vm._e(),(mensagem.type === 'image')?_c('div',{staticClass:"message-normal"},[(mensagem.status == 'DELETED')?_c('span',{staticClass:"cinza"},[_vm._v(" Imagem apagada")]):_vm._e(),(mensagem.status != 'DELETED')?_c('span',[_c('a',{attrs:{"href":mensagem.url_link,"target":"_blank"}},[_c('img',{staticClass:"img-thumbnail",attrs:{"src":mensagem.url_link,"alt":""}})]),_c('br'),_vm._v(_vm._s(mensagem.mensagem)+" "),(mensagem.wook == 'onack')?_c('div',{staticClass:"alinhadireita"},[(mensagem.status == 'RECEIVED')?_c('i',{staticClass:"fas fa-check-double cinza"}):_vm._e(),(mensagem.status == 'SENT')?_c('i',{staticClass:"fas fa-check"}):_vm._e(),(mensagem.status == 'READ'  )?_c('i',{staticClass:"fas fa-check-double text-success"}):_vm._e()]):_vm._e(),(!_vm.estadoEncaminharMensagens)?_c('div',{staticClass:"pd-2 cursor-pointer box_abre_selecoes",on:{"click":_vm.abreSelecoes}},[_c('i',{staticClass:"fas fa-chevron-down abrir-selecoes"}),_c('div',{staticClass:"box-opcoes"},_vm._l((_vm.option),function(opcoes,index){return _c('span',{key:index,staticClass:"d-block mb-2",on:{"click":function($event){return _vm.escolhaSelecionado(
                    opcoes.id,
                    mensagem.message_id,
                    mensagem.mensagem
                  )}}},[_vm._v(" "+_vm._s(opcoes.msg)+" ")])}),0)]):_vm._e()]):_vm._e()]):_vm._e(),(mensagem.type === 'document')?_c('div',{staticClass:"message-normal"},[(mensagem.status == 'DELETED')?_c('span',{staticClass:"cinza"},[_vm._v(" Documento deletado")]):_vm._e(),(mensagem.status != 'DELETED')?_c('span',[_c('a',{attrs:{"href":mensagem.url_link,"target":"_blank","download":mensagem.url_link}},[_vm._m(0,true)]),(mensagem.wook == 'onack')?_c('div',{staticClass:"alinhadireita"},[(mensagem.status == 'RECEIVED')?_c('i',{staticClass:"fas fa-check-double cinza"}):_vm._e(),(mensagem.status == 'SENT')?_c('i',{staticClass:"fas fa-check"}):_vm._e(),(mensagem.status == 'READ'  )?_c('i',{staticClass:"fas fa-check-double text-success"}):_vm._e()]):_vm._e(),(!_vm.estadoEncaminharMensagens)?_c('div',{staticClass:"pd-2 cursor-pointer box_abre_selecoes",on:{"click":_vm.abreSelecoes}},[_c('i',{staticClass:"fas fa-chevron-down abrir-selecoes"}),_c('div',{staticClass:"box-opcoes"},_vm._l((_vm.option),function(opcoes,index){return _c('span',{key:index,staticClass:"d-block mb-2",on:{"click":function($event){return _vm.escolhaSelecionado(
                    opcoes.id,
                    mensagem.message_id,
                    mensagem.mensagem
                  )}}},[_vm._v(" "+_vm._s(opcoes.msg)+" ")])}),0)]):_vm._e()]):_vm._e()]):_vm._e(),_c('span',{staticClass:"info mt-2",staticStyle:{"font-size":"0.7rem"}},[_vm._v(" "+_vm._s(mensagem.name)+" "+_vm._s(mensagem.updated_at)+" ")])])])})],2):_c('div',{directives:[{name:"chat-scroll",rawName:"v-chat-scroll"}],staticClass:"chatBox"},[_c('h1',[_vm._v(" "+_vm._s(_vm.mensagens)+" ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-danger btn-block"},[_vm._v(" Baixar documento "),_c('i',{staticClass:"fa fa-download"})])
}]

export { render, staticRenderFns }