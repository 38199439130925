<template>

  <container class="p-1" >

    <div class="d-flex flex-column align-items-center mb-2 mt-2">
      <h1 class="fw-bold">CONFIGURAÇÃO CHAT BOT</h1>
    </div>

    <div class="container-fluid bg-white" style="padding: 20px">
      <div class="form-check form-switch ms-4">
        <input
          class="form-check-input fs-2"
          type="checkbox"
          :checked="statusChatBot"
          role="switch"
          id="flexSwitchCheckDefault"
          @change="ativarChatBot"
        />

        <label v-if="statusChatBot == 0" class="form-check-label fs-2" for="flexSwitchCheckDefault"
          >Ativar Chatbot</label>
          <label v-if="statusChatBot == 1 " class="form-check-label fs-2" for="flexSwitchCheckDefault"
          >Desativar Chatbot</label>
      </div>


      <h4 class="text-center fw-bold mt-3 mb-3">OPÇÕES</h4>

      <div class="input-group mb-3">
        <span class="input-group-text">Saudação Inicial</span>
        <textarea v-if="statusChatBot != 0"
          class="form-control"
          aria-label="With textarea"
          v-model="objMenu.pergunta"
          rows="8"
        ></textarea>
      </div>

      
      <div class="input-group mb-3" v-if="tipo_chatbot == 2" >
        <span class="input-group-text">Quantidade de opções</span> 

        <input  v-model.number="objMenu.qtd_opcao"  type="number" class="form-control" >

        <button class="button btn-success form-control"  @click="grava_opcoes(1)">Inserir</button> 

        </div>
       
       
        <div class="table-responsive d-flex flex-column align-items-center mt-4" v-if="tipo_chatbot == 2">
          <button   class="btn btn-success fs-3"
                    @click="teste()"                  >
                   <label  >Editar</label> 
                  </button>
         
          <table class="table table-striped table-hover mb-4">
        <thead>
          <tr>
            
            <th>Título</th>
            <th>Resposta</th>
            <th >Subopções</th>
            
          </tr>
        </thead>
        <tbody>
          <tr v-for="(dados, indice) in dados_opcao" >

            
            <td v-if="edita" > 
              <input   class="form-control" @change="editar(2, dados.id)"    v-model="dados.texto_opcao" 
              placeholder="Digite a nova opção opção" type="text">{{ dados.texto_opcao }}
              <input :value="dados.texto_opcao" @change="$emit('input',$event.target.value)"> 
            </td>
            <td v-if="!edita">  </td>
           
            <td><textarea v-if="edita"  class="form-control" @change="editar(2, dados.id)"    v-model="dados.resposta"></textarea>
            <span v-if="!edita"> {{ dados.resposta }}</span>
            </td>
          <td ><select v-if="edita"  v-model="dados.indice_tipo"  class="form-control"  >
          <option value="0" >Selecione uma opção? </option>
          <option  value="1" >Resposta</option>
          <option   value="2" >Subopção</option>
          </select> 
          <span v-if="dados.indice_tipo == 1 && edita == false">Resposta</span>
          <span v-if="dados.indice_tipo == 2 && edita == false">{{ dados.qtd_sub }}  </span>
                    
        </td>
                 
          </tr>
        </tbody>
      </table>
       </div>
       
<!--        
        <div
          v-for="(dados, indice) in dados_opcao" 
       :key="indice" class="input-group mb-1" >
       <span class="input-group-text mb-1"  >Opção {{ dados.indice_opcao}} </span> 
        <input  class="form-control"    v-model="dados.texto_opcao" 
       
       placeholder="Digite a opção" type="text">
       
       <textarea  v-model="dados.resposta" class="form-control" > </textarea>
       
        <select  v-model="dados.indice_tipo"  class="form-control"  >
          <option value="0" >Selecione uma opção? </option>
          <option  value="1" >Resposta</option>
          <option   value="2" >Subopção</option>
          </select>

        <input v-if="dados.indice_tipo == 2 && dados.qtd_sub == 0" :ref="dados.id" :value="dados.qtd_subopcao"  
        @change="$emit('input', $event.target.value)"
        class="form-control" placeholder="Quantidade de 1 a 6" type="number">
        <input v-if="dados.indice_tipo == 2 && dados.qtd_sub != 0" :ref="dados.id" :value="dados.qtd_sub"
        @input="$emit('input', $event.target.value)"
        class="form-control" placeholder="Quantidade de 1 a 6" type="number">

        <button v-if="dados.indice_tipo == 2"   class="button btn-success form-control"   @click="grava_subopcoes(2, dados.id)">Inserir</button> 
        {{ dados.qtd_sub }}
       
        <div v-for="(dados_sub, index_sub) in dados.qtd_sub" class="input-group mb-1"> 
          
          <div   class="input-group mb-1">
           
            
            <span class="input-group-text" id="basic-addon1">Subopção {{ index_sub+1 }}</span>
            <input  type="text" class="form-control" v-model="dados_sub.texto"  placeholder="Digite o texto" aria-label="Username" aria-describedby="basic-addon1">
          
            <select v-model="dados_sub.indice_tipo"  class="form-control"> 
             
              <option value="1"> Resposta em texto </option>
              <option value="2"> Resposta em arquivo</option>
            </select>
            <input v-if="dados_sub.indice_tipo == 1 " v-model="dados_sub.sub_resposta" type="text" class="form-control" placeholder="Digite a resposta"  aria-describedby="basic-addon1">
            <input v-if="dados_sub.indice_tipo == 2 && dados_sub.link_arquivo == 'NULL'"  type="file" class="form-control" placeholder="Selecione o arquivo" aria-describedby="basic-addon1">
            <input v-if="dados_sub.indice_tipo == 2 && dados_sub.link_arquivo != 'NULL'" v-model="dados_sub.link_arquivo"  >
         
      </div>
       

      </div> fim vsubopção-->
   
    </div>
    

      <div >
       
        <div v-if="tipo_chatbot == 1" >
      <div class="input-group mb-3" >
        <span class="input-group-text">Setores</span>
        <textarea
          disabled
          class="form-control"
          aria-label="With textarea"
          v-model="objMenu.setores"
          rows="5"
        >
        </textarea>
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text">Resposta depois da escolha</span>
        <textarea v-if="statusChatBot != 0"
          class="form-control"
          aria-label="With textarea"
          v-model="objMenu.resposta"
        ></textarea>
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text"
          >Resposta após selecionada uma opção</span
        >
        <textarea v-if="statusChatBot != 0"
          class="form-control"
          aria-label="With textarea"
          v-model="objMenu.resposta_selecionado"
        ></textarea>
      </div>
      <div class="input-group mb-3">
        <span class="input-group-text">Resposta de finalização</span>
        <textarea v-if="statusChatBot != 0"
          class="form-control"
          aria-label="With textarea"
          v-model="objMenu.resposta_final"
        ></textarea>
      </div>
      
      
      <div class="input-group mb-3" v-if="statusChatBot != 0">
        <span class="input-group-text">Horário de atendimento </span>
        <div class="inputs">
          <span>Inicio expediente</span>
          <input
            type="time"
            placeholder="Inicio expediente"
            class="form-control"
            v-model="objMenu.hora1"
          />
          <span>Fim expediente</span>
          <input
            type="time"
            placeholder="Fim expediente"
            class="form-control"
            v-model="objMenu.hora2"
          />
          <span>Inicio almoço</span>
          <input
            type="time"
            placeholder="Inicio almoço"
            class="form-control"
            v-model="objMenu.hora3"
          />
          <span>Fim almoço</span>
          <input
            type="time"
            placeholder="Fim almoço"
            class="form-control"
            v-model="objMenu.hora4"
          />
        </div>
      </div>
      <div class="input-group mb-3" v-if="statusChatBot != 0">
        <span class="input-group-text">Dias de atendimento </span>

       
        <div class="dias_semana">
          <input
            type="checkbox"
            class="form-check-input"
            :data-id="objMenu.segunda"
            :checked="objMenu.segunda == 1"
            @click="checked($event, 'segunda')"
          />
          <span>Segunda</span>
        </div>
        <div class="dias_semana">
          <input
            type="checkbox"
            class="form-check-input"
            :data-id="objMenu.terca"
            :checked="objMenu.terca == 1"
            @click="checked($event, 'terca')"
          />
          <span>Terça</span>
        </div>
        <div class="dias_semana">
          <input
            type="checkbox"
            class="form-check-input"
            :data-id="objMenu.quarta"
            :checked="objMenu.quarta == 1"
            @click="checked($event, 'quarta')"
          />
          <span>Quarta</span>
        </div>
        <div class="dias_semana">
          <input
            type="checkbox"
            class="form-check-input"
            :data-id="objMenu.quinta"
            :checked="objMenu.quinta == 1"
            @click="checked($event, 'quinta')"
          />
          <span>Quinta</span>
        </div>
        <div class="dias_semana">
          <input
            type="checkbox"
            class="form-check-input"
            :data-id="objMenu.sexta"
            :checked="objMenu.sexta == 1"
            @click="checked($event, 'sexta')"
          />
          <span>Sexta</span>
        </div>
        <div class="dias_semana">
          <input
            type="checkbox"
            class="form-check-input"
            :data-id="objMenu.sabado"
            :checked="objMenu.sabado == 1"
            @click="checked($event, 'sabado')"
          />
          <span>Sabádo</span>
        </div>
        <div class="dias_semana">
          <input
            type="checkbox"
            class="form-check-input"
            :data-id="objMenu.domingo"
            :checked="objMenu.domingo == 1"
            @click="checked($event, 'domingo')"
          />
          <span>Domingo</span>
        </div>
      </div>
    </div>

      <button v-if="statusChatBot != 0"
        class="btn btn-primary fs-4"
        style="padding: 10px 15px"
        @click="chamarDadosChatBot(1)"
      >
        Salvar
      </button>
    </div>
  </container>
  
  <!-- <container class="p-1" v-else="!statusChatBot">


<div class="d-flex flex-column align-items-center mb-2 mt-2">
  <h1 class="fw-bold">SEU PLANO NÃO POSSUI CHATBOT</h1>
</div>
</container> -->


</template>

<script>
import api from "@/services/api";
import container from "../GLOBALS/container.vue";
import Swal from "sweetalert2";
export default {
  name: "ChatBoot",
  components: { container },
  data() {
    return {
    edita: false,
    dados: {
      texto_opcao: "",
      indice_tipo: 0,
    },  
    nome:'sub',
    
    dados_opcao: [],
    dados_subopcao: [],
      setores: [],
      tipo: 0,
      id_sub: 0,
      objMenu: {
        texto_opcao: "",
        qtd_subopcao: 0,
        qtd_opcao: 0,
        opcao: [],
        subopcao: [],
        indice_tipo: 0,      
        pergunta: "",
        resposta: "",
        resposta_final: "",
        resposta_selecionado: "",
        hora1: "",
        hora2: "",
        hora3: "",
        hora4: "",
        dias_semana: [],
        setores: [],
        segunda: 0,
        terca: 0,
        quarta: 0,
        quinta: 0,
        sexta: 0,
        sabado: 0,
        domingo: 0,
    
        tipo_opcao0: 0,
         

       },
      processando: false,   
      grava: 0,
     

      mascara: {
        hora: "##:##",
      },

      statusChatBot: false,
      tipo_chatbot: 0
    };
  },
  mounted() {
    this.chamarDadosChatBot();
    this.chamarSetores();
    this.dadoschatBot_opcao();
  },
  methods: {
   
   grava_opcoes(tipo){
    
    this.precessando = true;
    let obj = {
        tipo: tipo,
         id: localStorage.getItem("@USER_ID"),
        ...this.objMenu,
        
      };

      api
        .post("/chatbot_opcao/ZmlsYWRlYXRlbmRpbWVudG8=", obj)
        .then((response) => {
          let data = response.data;
          // console.log(data);
          this.processando = false;
          if (data.atualizado) {
            Swal.fire({
              position: "center",
              icon: "success",  
              title: "ATUALIZADO COM SUCESSO!",
              showConfirmButton: true,
            });
          }
            // this.statusChatBot = data.ativo;
          this.dadoschatBot_opcao(); 
            if (data.dados === null) return;
         
            })
        .catch((error) => {
          console.log(error);
        });

   },
   teste(){
    this.edita = !this.edita;
   },
   editar(tipo, id){
    this.objMenu.texto_opcao = this.dados.texto_opcao
    console.log(this.objMenu.texto_opcao)
    let obj = {
        id_sub: id,
        tipo: tipo,
         id: localStorage.getItem("@USER_ID"),
         ...this.dados,
         
        
      };
    
    
   },
   grava_subopcoes(tipo, id){
    
    this.precessando = true;
    let obj = {
        id_sub: id,
        tipo: tipo,
         id: localStorage.getItem("@USER_ID"),
         ...this.objMenu,
        
      };
      
      api
        .post("/chatbot_opcao/ZmlsYWRlYXRlbmRpbWVudG8=", obj)
        .then((response) => {
          let data = response.data;
          // console.log(data);
          this.processando = false;
          if (data.atualizado) {
            Swal.fire({
              position: "center",
              icon: "success",  
              title: "ATUALIZADO COM SUCESSO!",
              showConfirmButton: true,
            });
          }

            // this.statusChatBot = data.ativo;
          
            if (data.dados === null) return;
         
            })
        .catch((error) => {
          console.log(error);
        });

   },
    dadoschatBot_opcao(grava) {
      this.grava = grava;
      
      let obj = {
        grava: this.grava,
        
        id: localStorage.getItem("@USER_ID"),
        ...this.objMenu,
      };

      api
        .post("/chatbot_geral/ZmlsYWRlYXRlbmRpbWVudG8=", obj)
        .then((response) => {
          let data = response.data;
          // console.log(data);
          if (data.atualizado) {
            Swal.fire({
              position: "center",
              icon: "success",  
              title: "ATUALIZADO COM SUCESSO!",
              showConfirmButton: true,
            });
          }

        //   // this.statusChatBot = data.ativo;
          this.objMenu.qtd_opcao = data.qtd_opcao;
                   
           this.dados_opcao = data.dados;

           this.dados_subopcao = data.subopcao;
          

        //  this.dados_opcao.forEach((value, index) => {
        //    this.objMenu.opcao.push(value);
        //        });


        //     this.dados_subopcao.forEach((value, index) => {
              
        //       this.objMenu.subopcao.push(value);
        //           });  
            if (data.dados === null) return;

                   

        })
        .catch((error) => {
          console.log(error);
        });
    },
    
    chamarDadosChatBot(grava) {
      this.grava = grava;

      let obj = {
        grava: this.grava,
        id: localStorage.getItem("@USER_ID"),
        ...this.objMenu,
      };

      api
        .post("/chatbotapi/ZmlsYWRlYXRlbmRpbWVudG8=", obj)
        .then((response) => {
          let data = response.data;
          // console.log(data);
          if (data.atualizado) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "ATUALIZADO COM SUCESSO!",
              showConfirmButton: true,
            });
          }
          this.tipo_chatbot = data.tipo;
          this.statusChatBot = data.ativo;


          if (data.dados === null) return;

          this.objMenu.pergunta = data.dados.pergunta;
          this.objMenu.resposta = data.dados.resposta;
          this.objMenu.resposta_final = data.dados.resposta_final;
          this.objMenu.resposta_selecionado = data.dados.resposta_selecionado;
          this.objMenu.resposta_selecionado = data.dados.resposta_selecionado;
          this.objMenu.hora1 = data.dados.hora1;
          this.objMenu.hora2 = data.dados.hora2;
          this.objMenu.hora3 = data.dados.hora3;
          this.objMenu.hora4 = data.dados.hora4;

          this.objMenu.segunda = data.dados.segunda;
          this.objMenu.terca = data.dados.terca;
          this.objMenu.quarta = data.dados.quarta;
          this.objMenu.quinta = data.dados.quinta;
          this.objMenu.sexta = data.dados.sexta;
          this.objMenu.sabado = data.dados.sabado;
          this.objMenu.domingo = data.dados.domingo;

          // this.objMenu.dias_semana = data.dados.dias_semana;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    chamarSetores() {
      api
        .post("/setores/ZmlsYWRlYXRlbmRpbWVudG8=", {
          id: localStorage.getItem("@USER_ID"),
        })
        .then((response) => {
          let data = response.data;
          this.setores = data.setores;

          let textoFormatado = this.setores.reduce((acc, element) => {
            acc += element.vinculo_id + " - " + element.nome + "\n";

            return acc;
          }, "");

          this.objMenu.setores = "\n" + textoFormatado;
        })
        .catch((erro) => console.log(erro));
    },
    ativarChatBot() {
      this.statusChatBot = !this.statusChatBot;

      let obj = {
        id: localStorage.getItem("@USER_ID"),
        ativo: this.statusChatBot,
      };

      api
        .post("/ativar_chatbot/ZmlsYWRlYXRlbmRpbWVudG8=", obj)
        .then((response) => {
          let data = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    checked({ target }, dia) {
      let checkbox = target;
      const id = checkbox.getAttribute("data-id");

      if (checkbox.checked) {
   //     console.log("marcado");
        this.objMenu[dia] = 1;
        checkbox.setAttribute("data-id", 1);
      } else {
        z;
    //    console.log("nao marcado");
        this.objMenu[dia] = 0;
        checkbox.setAttribute("data-id", 0);
      }

      // console.log(this.objMenu);
    },
  },
};
</script>
<style scoped>
.box {
  width: 50%;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);
}
.container {
  padding-top: 2%;
  margin-left: 15%;
}

.inputs {
  display: flex;
  padding-left: 10px;
  gap: 10px;
}
.inputs input {
  padding: 10px;
}

.dias_semana {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.dias_semana input {
  padding: 10px;
}
.dias_semana span {
  font-size: 0.985rem;
}

@media (max-width: 1200px) {
  .inputs {
    margin-top: 10px;
  }
}
@media (max-width: 600px) {
  .inputs {
    flex-direction: column;
  }
}
</style>
