<template>

<div
    v-if="status"
    class="chatBox"
    v-chat-scroll
    :class="estadoResponderMensagem ? 'layoutResponderAlturaTeclado' : ''"
  >
    <div
      class="fundo-transparente"
      @click="limparBoxOpcoes"
      v-if="abrirBoxOpcoes"
    ></div>
    <div v-if="processando" >Atualizando mensagens...
    </div>

    <div
      class="message"
      :class="{ my_message: mensagem.fone_enviado !== foneAtendido }"
      v-for="(mensagem, index) in mensagens"
      :key="index"
    >
      <label class="conteiner" v-if="estadoEncaminharMensagens">
        <input type="checkbox" class="form-check-input selecionarMensagens" />
        <span
          class="checkmark"
          @click="marcarMensagem"
          :data-id="mensagem.message_id"
          :data-type="mensagem.type"
        ></span>
      </label>


      <div class="box-mensagens">
    
        <div v-if="mensagem.type === 'text'" class="message-normal">
            <span v-if="mensagem.status == 'DELETED'" class="cinza"> Mensagem apagada</span>
            <span v-if="mensagem.status != 'DELETED'"> 
          {{ mensagem.mensagem }}  
          
          <div v-if="mensagem.wook == 'onack'" class="alinhadireita">
          <i v-if="mensagem.status == 'RECEIVED'" class="fas fa-check-double cinza"></i>
          <i v-if="mensagem.status == 'SENT'" class="fas fa-check"></i>
          <i v-if="mensagem.status == 'READ'  " class="fas fa-check-double text-success"></i></div>
          <div
            class="pd-2 cursor-pointer box_abre_selecoes"
            @click="abreSelecoes"
            v-if="!estadoEncaminharMensagens"
          >
            <i class="fas fa-chevron-down abrir-selecoes"></i>
            <div class="box-opcoes">
              <span
                v-for="(opcoes, index) in option"
                :key="index"
                class="d-block mb-2"
                @click="
                  escolhaSelecionado(
                    opcoes.id,
                    mensagem.message_id,
                    mensagem.mensagem
                  )
                "
              >
                {{ opcoes.msg }}
              </span>
            </div>
          </div>
        </span>
        </div>

        <div v-if="mensagem.type === 'vcard'" class="vcard message-normal">
          <span v-if="mensagem.status == 'DELETED'" class="cinza"> Mensagem apagada</span>
            <span v-if="mensagem.status != 'DELETED'"> 
          <div class="vcard-header">
            <img src="@/assets/default.png" alt="" class="user_foto" />
            {{ mensagem.contactName }}<br />
            {{ mensagem.mensagem }}
            <div v-if="mensagem.wook == 'onack'" class="alinhadireita">
          <i v-if="mensagem.status == 'RECEIVED'" class="fas fa-check-double cinza"></i>
          <i v-if="mensagem.status == 'SENT'" class="fas fa-check"></i>
          <i v-if="mensagem.status == 'READ'  " class="fas fa-check-double text-success"></i></div>
            <div
              class="pd-2 cursor-pointer box_abre_selecoes"
              @click="abreSelecoes"
              v-if="!estadoEncaminharMensagens"
            >
              <i class="fas fa-chevron-down abrir-selecoes"></i>
              <div class="box-opcoes">
                <span
                  v-for="(opcoes, index) in option"
                  :key="index"
                  class="d-block mb-2"
                  @click="
                    escolhaSelecionado(
                      opcoes.id,
                      mensagem.message_id,
                      mensagem.mensagem
                    )
                  "
                >
                  {{ opcoes.msg }}
                </span>
              </div>
            </div>
          </div>
          <div class="vcard-body">
            <button @click="abrirConversa(mensagem)" class="conversar">Conversar</button>
          </div></span>
        </div>
        <div v-if="mensagem.type === 'ptt'" class="message-normal">
          <span v-if="mensagem.status == 'DELETED'" class="cinza"> Audio apagado</span>
            <span v-if="mensagem.status != 'DELETED'"> 
          <audio controls="controls" style="max-width: 100%">
            <source
              id="audiobase64"
              :src="mensagem.url_link"
              controls
              autoplay
            />
          </audio>
          <div v-if="mensagem.wook == 'onack'" class="alinhadireita">
          <i v-if="mensagem.status == 'RECEIVED'" class="fas fa-check-double cinza"></i>
          <i v-if="mensagem.status == 'SENT'" class="fas fa-check"></i>
          <i v-if="mensagem.status == 'READ'  " class="fas fa-check-double text-success"></i></div>
          
          <div
            class="pd-2 cursor-pointer box_abre_selecoes"
            @click="abreSelecoes"
            v-if="!estadoEncaminharMensagens"
          >
            <i class="fas fa-chevron-down abrir-selecoes"></i>
            <div class="box-opcoes">
              <span
                v-for="(opcoes, index) in option"
                :key="index"
                class="d-block mb-2"
                @click="
                  escolhaSelecionado(
                    opcoes.id,
                    mensagem.message_id,
                    mensagem.mensagem
                  )
                "
              >
                {{ opcoes.msg }}
              </span>
            </div>
          </div>
        </span>
        </div>

        <div v-if="mensagem.type === 'sticker'" class="message-normal">
          <span v-if="mensagem.status == 'DELETED'" class="cinza"> Mensagem apagada</span>
            <span v-if="mensagem.status != 'DELETED'"> 
          <img :src="mensagem.url_link" class="img-thumbnail" />
          <div v-if="mensagem.wook == 'onack'" class="alinhadireita">
          <i v-if="mensagem.status == 'RECEIVED'" class="fas fa-check-double cinza"></i>
          <i v-if="mensagem.status == 'SENT'" class="fas fa-check"></i>
          <i v-if="mensagem.status == 'READ'  " class="fas fa-check-double text-success"></i></div>
          <div
            class="pd-2 cursor-pointer box_abre_selecoes"
            @click="abreSelecoes"
            v-if="!estadoEncaminharMensagens"
          >
            <i class="fas fa-chevron-down abrir-selecoes"></i>
            <div class="box-opcoes">
              <span
                v-for="(opcoes, index) in option"
                :key="index"
                class="d-block mb-2"
                @click="
                  escolhaSelecionado(
                    opcoes.id,
                    mensagem.message_id,
                    mensagem.mensagem
                  )
                "
              >
                {{ opcoes.msg }}
              </span>
            </div>
          </div>
          </span>
        </div>

        <div v-if="mensagem.type === 'link'" class="message-normal">
          <span v-if="mensagem.status == 'DELETED'" class="cinza"> Mensagem apagada</span>
            <span v-if="mensagem.status != 'DELETED'"> 
            >{{ mensagem.mensagem }}
            <div v-if="mensagem.wook == 'onack'" class="alinhadireita">
          <i v-if="mensagem.status == 'RECEIVED'" class="fas fa-check-double cinza"></i>
          <i v-if="mensagem.status == 'SENT'" class="fas fa-check"></i>
          <i v-if="mensagem.status == 'READ'  " class="fas fa-check-double text-success"></i></div>
         
          <div
            class="pd-2 cursor-pointer box_abre_selecoes"
            @click="abreSelecoes"
            v-if="!estadoEncaminharMensagens"
          >
            <i class="fas fa-chevron-down abrir-selecoes"></i>
            <div class="box-opcoes">
              <span
                v-for="(opcoes, index) in option"
                :key="index"
                class="d-block mb-2"
                @click="
                  escolhaSelecionado(
                    opcoes.id,
                    mensagem.message_id,
                    mensagem.mensagem
                  )
                "
              >
                {{ opcoes.msg }}
              </span>
            </div>
          </div>
          </span>
        </div>

        <div v-if="mensagem.type === 'video'" class="message-normal">
          <span v-if="mensagem.status == 'DELETED'" class="cinza"> Vídeo apagado</span>
            <span v-if="mensagem.status != 'DELETED'"> 
          <video width="50%" controls>
            <source type="video/mp4" :src="mensagem.url_link" />
          </video>
          <div v-if="mensagem.wook == 'onack'" class="alinhadireita">
          <i v-if="mensagem.status == 'RECEIVED'" class="fas fa-check-double cinza"></i>
          <i v-if="mensagem.status == 'SENT'" class="fas fa-check"></i>
          <i v-if="mensagem.status == 'READ'  " class="fas fa-check-double text-success"></i></div>
          <div
            class="pd-2 cursor-pointer box_abre_selecoes"
            @click="abreSelecoes"
            v-if="!estadoEncaminharMensagens"
          >
            <i class="fas fa-chevron-down abrir-selecoes"></i>
            <div class="box-opcoes">
              <span
                v-for="(opcoes, index) in option"
                :key="index"
                class="d-block mb-2"
                @click="
                  escolhaSelecionado(
                    opcoes.id,
                    mensagem.message_id,
                    mensagem.mensagem
                  )
                "
              >
                {{ opcoes.msg }}
              </span>
            </div>
          </div>
          </span>
        </div>

        <div v-if="mensagem.type === 'image'" class="message-normal">
          <span v-if="mensagem.status == 'DELETED'" class="cinza"> Imagem apagada</span>
            <span v-if="mensagem.status != 'DELETED'"> 
          <a :href="mensagem.url_link" target="_blank">
            <img :src="mensagem.url_link" alt="" class="img-thumbnail"
          /></a>
          <br />{{ mensagem.mensagem }}
          <div v-if="mensagem.wook == 'onack'" class="alinhadireita">
          <i v-if="mensagem.status == 'RECEIVED'" class="fas fa-check-double cinza"></i>
          <i v-if="mensagem.status == 'SENT'" class="fas fa-check"></i>
          <i v-if="mensagem.status == 'READ'  " class="fas fa-check-double text-success"></i></div>
          <div
            class="pd-2 cursor-pointer box_abre_selecoes"
            @click="abreSelecoes"
            v-if="!estadoEncaminharMensagens"
          >
            <i class="fas fa-chevron-down abrir-selecoes"></i>
            <div class="box-opcoes">
              <span
                v-for="(opcoes, index) in option"
                :key="index"
                class="d-block mb-2"
                @click="
                  escolhaSelecionado(
                    opcoes.id,
                    mensagem.message_id,
                    mensagem.mensagem
                  )
                "
              >
                {{ opcoes.msg }}
              </span>
            </div>
          </div>
          </span>
        </div>

        <div v-if="mensagem.type === 'document'" class="message-normal">
          <span v-if="mensagem.status == 'DELETED'" class="cinza"> Documento deletado</span>
            <span v-if="mensagem.status != 'DELETED'"> 
          <a
            :href="mensagem.url_link"
            target="_blank"
            :download="mensagem.url_link"
          >
            <button class="btn btn-danger btn-block">
              Baixar documento
              <i class="fa fa-download"></i>
            </button>
          </a>
          <div v-if="mensagem.wook == 'onack'"   class="alinhadireita">
          <i v-if="mensagem.status == 'RECEIVED'" class="fas fa-check-double cinza"></i>
          <i v-if="mensagem.status == 'SENT'" class="fas fa-check"></i>
          <i v-if="mensagem.status == 'READ'  " class="fas fa-check-double text-success"></i></div>
          <div
            class="pd-2 cursor-pointer box_abre_selecoes"
            @click="abreSelecoes"
            v-if="!estadoEncaminharMensagens"
          >
            <i class="fas fa-chevron-down abrir-selecoes"></i>
            <div class="box-opcoes">
              <span
                v-for="(opcoes, index) in option"
                :key="index"
                class="d-block mb-2"
                @click="
                  escolhaSelecionado(
                    opcoes.id,
                    mensagem.message_id,
                    mensagem.mensagem
                  )
                "
              >
                {{ opcoes.msg }}
              </span>
            </div>
          </div>
        </span>
        </div>
        <span class="info mt-2" style="font-size: 0.7rem">
          {{ mensagem.name }} {{ mensagem.updated_at }}
        </span>
        
      </div>
    </div>
  </div>
  <div class="chatBox" v-chat-scroll v-else>
    <h1>
      {{ mensagens }}
    </h1>
  </div>
</template>

<script>
export default {
  name: "ChatAtendimento",
  data() {
    return {
      mostrar: "",
      option: [
        // {
        //   id: 1,
        //   msg: "Encaminhar mensagem",
        // },
        {
          id: 2,
          msg: "Apagar mensagem",
        },
        {
          id: 3,
          msg: "Responder ",
        },
      ],
      id_mensagem: "",
      fone: "",
      abrirBoxOpcoes: false,
    };
  },
  methods: {
    abreSelecoes(e) {
      this.limparBoxOpcoes();

      this.abrirBoxOpcoes = true;

      let elemento = e.target;
      let elementoPai = elemento.parentNode;
      this.boxElementoPai = elementoPai;

      if (!elementoPai.classList.contains("box_abre_selecoes")) {
        let NovoElementoPai = elementoPai.parentNode;

        elementoPai = NovoElementoPai;
        this.boxElementoPai = NovoElementoPai;
      }

      let box_opcoes = elementoPai.querySelector(".box-opcoes");

      box_opcoes.classList.add("on");
    },
    escolhaSelecionado(opcao, id, mensagem) {
      this.abrirBoxOpcoes = false;

      switch (opcao) {
        // case 1:
        //   this.alterarLayoutBatePapo();
        //   this.limparBoxOpcoes();

        //   break;

          case 2:
          this.abremodal_apagarmensagem(id, mensagem);
          this.limparBoxOpcoes();
          
          break;
          

        case 3:
          // console.log("responder");
          this.responderLayout(mensagem, id);
          this.limparBoxOpcoes();

          break;

        default:
          break;
      }
    },
    marcarMensagem(e) {
      // this.listaMensagensSelecionadas.push(id);

      let labelCheckBox = e.target;
      let elementoPai = labelCheckBox.parentNode;
      let checkbox = elementoPai.querySelector(".selecionarMensagens");

      let message_id = labelCheckBox.getAttribute("data-id");

      // // checkBoxs[i].checked = this.checkedAll ? false : true;

      if (checkbox.checked) {
        this.listaMensagensSelecionadas.splice(
          this.listaMensagensSelecionadas.indexOf(message_id),
          1
        );
      } else {
        this.listaMensagensSelecionadas.push(message_id);
      }

    },
    limparBoxOpcoes() {
      let box_opcoes = document.querySelectorAll(".box-opcoes");

      for (let i = 0; i < box_opcoes.length; i++) {
        box_opcoes[i].classList.remove("on");
      }
      this.abrirBoxOpcoes = false;
    },
  },  

  props: {
    mensagens: {
      type: [Array, String],
    },
    processando: {
      type:  Boolean,
    },
    status: {
      type: Boolean,
    },
    foneAtendido: {
      type: [String, Number],
    },
    conversa: {
      type: String,
    },
    estadoEncaminharMensagens: {
      type: Boolean,
    },
    alterarLayoutBatePapo: {
      type: Function,
    },

    abremodal_apagarmensagem: {
      type: Function,
    },
    estadoResponderMensagem: {
      type: Boolean,
    },
    responderLayout: {
      type: Function,
    },
    listaMensagensSelecionadas: {
      type: Array,
    },
    abrirConversa: {
      type: Function,
    },
   
  },
};
</script>

<style scoped>

.cinza{
  color:darkgray;
}
.alinhadireita {
  text-align: right;
}
.box-opcoes {
  display: none !important;
  background-color: #fff;
  z-index: 5;
  position: absolute;
  right: 1%;
  top: -130px;
  width: 200px;
  padding: 20px;
  /* gap: 20px; */
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.box-opcoes.on {
  display: block !important;
}

.user_foto {
  border-radius: 50%;

  width: 45px !important;
  height: 45px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.message .vcard {
  position: relative;
  right: 0;
  padding: 15px 20px;
  background-color: #efefef;
  border-radius: 10px;
  font-size: 0.9rem;
  word-break: break-all;

  display: flex;
  flex-direction: column;
}
.box_abre_selecoes {
  position: absolute;
  right: 0;
  top: 0;
}
.abrir-selecoes {
  position: absolute;
  top: 4px;

  right: 8px;
}

/* .pd-2 {
  cursor: pointer;
  padding: 5px;
} */

.vcard-header {
  width: 100%;
  display: flex;
  gap: 20px;

  align-items: center;
  justify-content: space-around;
}

.vcard-body {
  text-align: center;
  margin-top: 10px;
}

.chatBox {
  position: relative;
  width: 100%;
  height: calc(100% - 116px);
  padding: 20px;
  overflow-y: auto;
}

.layoutResponderAlturaTeclado {
  height: calc(100% - 200px) !important;
}

.selecionarMensagens {
  display: block;
}
.message {
  position: relative;
  display: flex;
 align-items: center;
  /* flex-direction: column; */

  /* margin: 10px 0; */
}

.box-mensagens {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  margin: 10px 0;
}
.message .message-normal {
  position: relative;
  right: 0;
  max-width: 90%;
  padding: 15px;
  background-color: #efefef;
  border-radius: 10px;
  font-size: 0.9rem;
  white-space: pre-line;
  word-break:  break-word; 
}

.message .select {
  background-color: #efefef;
  position: absolute;
  right: 1%;
  top: 2%;
  overflow: hidden;
  width: 50%;
  font-size: 0.9rem;
}
.message div img {
  object-fit: contain;
  width: 200px;
  height: 150px;
}

.message audio {
  max-width: 90%;
}

.my_message .box-mensagens {
  align-items: flex-end;
}

.my_message .message-normal {
  background-color: #d9fdd3;
}

.my_message .select {
  background-color: #d9fdd3;
  position: absolute;
  right: 0px;
  top: 2%;
  overflow: hidden;
  width: 50%;
  font-size: 0.9rem;
}
.frnd_message {
  align-items: flex-start;
}
/* .frnd_message div {
  text-align: left;
} */
.conteiner {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.conteiner input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: solid #68737a;
  cursor: pointer;
}

.conteiner:hover input ~ .checkmark {
  background-color: #ccc;
}

.conteiner input:checked ~ .checkmark {
  background-color: #f58634;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.conteiner input:checked ~ .checkmark:after {
  display: block;
}

.conteiner .checkmark:after {
  left: 5px;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fundo-transparente {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.6); */
  background-color: transparent;
}

.conversar {
  background-color: transparent;
  font-size: 1rem;
  font-weight: bold;
}





@media (max-width: 500px){
  .chatBox{
    height: calc(100% - 120px);

  }
}






</style>
