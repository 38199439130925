<template>
  <nav class="navbar navbar-expand navbar-light navbar-bg">
    <button
      class="sidebar-toggle js-sidebar-toggle bg-transparent ms-2"
      @click="menu"
    >
      <i class="hamburger align-self-center"></i>
    </button>

    <div class="navbar-collapse collapse">
      <ul class="navbar-nav navbar-align">
        <li class="nav-item dropdown">
          <a
            class="nav-icon dropdown-toggle d-inline-block d-sm-none"
            href="#"
            data-bs-toggle="dropdown"
          >
            <i class="align-middle" data-feather="settings"></i>
          </a>

          <a
            class="nav-link dropdown-toggle d-none d-sm-inline-block"
            href="#"
            data-bs-toggle="dropdown"
          >
            <span class="text-dark pe-1">{{ userName }}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-end">
            <a
              class="dropdown-item"
              @click="change('ATUALIZARCADASTRO')"
              href="#"
              ><i class="align-middle me-1" data-feather="user"></i> Atualizar
              cadastro</a
            >
            <div class="dropdown-divider"></div>

            <a
              class="dropdown-item"
              href="#"
              @click="buscaChamados"
              data-bs-toggle="offcanvas"
              data-bs-target="#abrirChamado"
              ><i class="align-middle me-1" data-feather="help-circle"></i>
              Abrir chamado</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" @click="deslogar()">Sair</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>


<script>
import middleware from "@/services/middleware";
export default {
  name: "AdmNavegation",
  data() {
    return {
      userName: null,
    };
  },
  props: {
    change: {
      type: Function,
    },
    buscaChamados: {
      type: Function,
    },
  },
  methods: {
    menu() {
      const el = document.querySelector(".sidebar");
      el.classList.toggle("collapsed");
    },
    deslogar() {
      middleware.logout();
    },
  },
  mounted() {
    this.userName = localStorage.getItem("@USER_NAME");
  },
};
</script>



