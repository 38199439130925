var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-color"},[_c('div',{staticClass:"container color-branco"},[_c('footer',{staticClass:"row py-5 my-5 border-top"},[_c('div',{staticClass:"col-md-3 col-12 mb-3"},[_c('router-link',{staticClass:"d-flex align-items-center mb-4 link-dark text-decoration-none",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../../assets/img/inzupt.png"),"alt":"","width":"142"}})])],1),_c('div',{staticClass:"col-md-3 col-12 mb-3"},[_c('h5',{staticClass:"text-white fs-3 fw-bold"},[_vm._v("Informações")]),_c('ul',{staticClass:"nav flex-column"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('li',{staticClass:"nav-item mb-2"},[_c('router-link',{staticClass:"nav-link p-0 text-white",attrs:{"to":"/politica_privacidade"}},[_vm._v("Política de privacidade")])],1)])]),_vm._m(3),_vm._m(4),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item mb-2"},[_c('a',{staticClass:"nav-link p-0 text-white",attrs:{"href":"#home"}},[_vm._v("Home")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item mb-2"},[_c('a',{staticClass:"nav-link p-0 text-white",attrs:{"href":"#planos"}},[_vm._v("Planos")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item mb-2"},[_c('a',{staticClass:"nav-link p-0 text-white",attrs:{"href":"#planos"}},[_vm._v("FAQ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 col-12 mb-3"},[_c('h5',{staticClass:"text-white fs-3 fw-bold"},[_vm._v("Contato")]),_c('ul',{staticClass:"nav flex-column"},[_c('li',{staticClass:"nav-item mb-2"},[_c('a',{staticClass:"nav-link p-0 text-white",attrs:{"href":"#"}},[_vm._v("(65) 4141-0643")])]),_c('li',{staticClass:"nav-item mb-2"},[_c('a',{staticClass:"nav-link p-0 text-white",attrs:{"href":"#"}},[_vm._v("atendimento@inzupt.com.br")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3 col-12 mb-4"},[_c('div',{staticClass:"links"},[_c('h5',{staticClass:"text-white fs-3 fw-bold"},[_vm._v("Redes Sociais")]),_c('a',{staticStyle:{"padding-left":"10px"},attrs:{"href":"https://www.facebook.com/inzapbrasil"}},[_c('img',{attrs:{"src":require("../../assets/img/homeFooter/facebook.png"),"alt":""}})]),_c('a',{staticStyle:{"padding-left":"10px"},attrs:{"href":"https://api.whatsapp.com/send/?phone=556541410643&text=Oi+acessei+o+site+https%3A%2F%2Finzupt.com.br+e+gostaria+de+mais+informa%C3%A7%C3%B5es&type=phone_number&app_absent=0"}},[_c('img',{attrs:{"src":require("../../assets/img/homeFooter/whatsapp.png"),"alt":""}})]),_c('a',{staticStyle:{"padding-left":"10px"},attrs:{"href":"https://www.instagram.com/inzapbrasil/"}},[_c('img',{attrs:{"src":require("../../assets/img/homeFooter/instagram (1).png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-12 mt-3"},[_c('div',{staticClass:"text-center fs-3"},[_vm._v("Copyright@INZUPT - 2022")])])
}]

export { render, staticRenderFns }