<template>
  <div class="chat-list">
    <div class="block" v-if="lista.length === 0">
      Não há contatos a ser atendido 
    </div>
    
    <div
      class="block"
      :class="{ active: ativado === contato.id }"
      v-for="contato in lista"
      :key="contato.id_atendimento"
      @click="
        change({
          usuario: contato,
        })
      "
    >
      <div class="imgbx">
        <img :src="contato.foto" alt="" />
      </div>
      <div class="details">
        <div
          class="name text-info"
          v-if="
           contato.nome_setor != null"
        >
          Setor {{ contato.nome_setor }}
        </div>

        <div
          class="name text-info"
          v-if="
            contato.hasOwnProperty('transferido') && contato.transferido != 0
          "
        >
          Tranferido por {{ contato.transferido }}
        </div>

        <div class="name fw-bold text-dark">{{ contato.nome }}</div>

        <div class="name fw-bold text-dark">
          {{ contato.fone }} 

          <span v-for="(fones, index) in lista_fone" :key="index">
            <span
              class="badge bg-warning rounded-pill"
              v-if="fones.fone == contato.fone && fones.qtdeMensagens != 0"
            >
              {{ fones.qtdeMensagens }}
            </span>
          </span>
        </div>

        <div class="name text-danger" style="font-size: 0.7rem;" v-if="tipo == 1">
          {{ contato.nome_atendente }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListaAtendimento",
  data() {
    return {
      novo1: "",
      cont: 0,
    };
  },

  props: {
    change: {
      type: Function,
    },
    lista: {
      type: Array,
    },
    ativado: {
      type: Number,
    },
    novo: {
      type: Boolean,
    },
    tipo: {
      type: [String, Number],
    },
    lista_fone: {
      type: Array,
    },
  },
  // filters: {

  // }
};
</script>

<style scoped>
img {
  max-width: 100%;
}
.chat-list {
  position: relative;
  overflow-y: auto;
  height: calc(100% - 110px);
}

.chat-list .block {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.block .imgbx {
  position: relative;
  width: 45px;
  height: 45px;
}
.img-text {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
}

.details {
  margin-left: 10px;
}

.imgbx img,
.img-text img {
  border-radius: 50%;
}

.block.active {
  background-color: #ebebeb;
}
.block:hover {
  background-color: #f5f5f5;
}
</style>
