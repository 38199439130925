import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueMaskDirective } from "v-mask";
import VueChatScroll from 'vue-chat-scroll';
import firebaseVue from './firebase/index';
import VueRecord from '@codekraft-studio/vue-record';

// import './firebase/index'

Vue.use(VueRecord);
Vue.use(firebaseVue);
Vue.directive("mask", VueMaskDirective);
Vue.use(VueChatScroll);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

