<template>
  <div class="planos">
    <div
      class="plano sombra p-5"
      v-for="(plano, index) in planos"
      :key="plano.id"
      :class="plano.nome.toLowerCase() === 'premium' ? 'border-laranja' : ''"
    >
      <h2 class="fs-1 fw-bold">{{ plano.nome }}</h2>
      <h3 class="card-title pricing-card-title text-dark fs-4">
        {{
          plano.valor | brMoneyFilter
        }}
      </h3>
      <p class="mt-3">
        {{ plano.resumo }}
      </p>

      <ul class="list-unstyled mt-3 mb-4">
        <!-- <li v-for="(conteudo, index) in plano.conteudo" :key="index">
          <i class="fa fa-check verde" aria-hidden="true"></i>
          10 users included
        </li> -->
        <li>
          <i class="fa fa-check verde" aria-hidden="true"></i>
          2 GB of storage
        </li>
        <li>
          <i class="fa fa-check verde" aria-hidden="true"></i> Email support
        </li>
        <li>
          <i class="fa fa-check verde" aria-hidden="true"></i>
          Help center access
        </li>
      </ul>
      <button class="bg-laranja fs-5 comprar">
        <router-link
          :to="{
            path: '/register',
            query: { id: plano.plano_id, posicao: index, registerSituation: plano.valor},
          }"
          class="text-white"
          >COMECE AGORA</router-link
        >
      </button>
    </div>
  </div>
</template>




<script>
export default {
  name: "BannerPlanosPrice",
  props: {
    planos: Array,
    planoNome: String,
  },
  filters: {
    brMoneyFilter(value) {
      if (value === 0) return "Consulte-nos";

      return value.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      }) + '/ mês';
    },
  },
};
</script>



<style scoped>
.border-laranja {
  border: 2px solid #f58634;
}
.mt-10 {
  margin-top: 200px;
}

.comprar {
  border-radius: 10px;
  padding: 10px 20px;
}

.verde {
  color: #36b37e;
}

.precos {
  margin: auto;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
}

.sombra {
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.2);
}

.preco {
  height: 400px;
  padding: 4px;
}

.bg-laranja-card {
  background-color: #f58634;
}

.planos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}
.planos .plano {
  border: 1px solid #cdcdcd;
  padding: 20px;
  border-radius: 10px;
}

@media (max-width: 1000px) {
  .precos {
    flex-wrap: wrap;
  }
  .planos {
    gap: 20px;
    grid-template-columns: 1fr;
  }
}
</style>