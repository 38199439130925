<template>
  <div class="wrapper">
    <AdmMenu :option="options" :selected="selected" :change="setSelect" />

    <div class="main">
      <AdmNavegation
        :change="setSelect"
        :busca-chamados="buscarDadosChamados"
      />

      <div class="container-fluid p-0 h-100 bg-cinza">
        <component :is="selected"></component>
        <ABRIRCHAMADO :chamados="chamados" />
        <ADDCHAMADO />
      </div>

      <footer class="footer">
        <div class="container-fluid">
          <div class="row text-muted">
            <div class="col-6 text-start">
              <p class="mb-0">
                <a
                  class="text-muted"
                  href="https://adminkit.io/"
                  target="_blank"
                  ><strong>INZUPT</strong></a
                >
                &copy;
              </p>
            </div>
            <div class="col-6 text-end">
              <ul class="list-inline">
                
                <li class="list-inline-item">
                  <router-link
                    class="text-muted"
                    target="_blank"
                    to="/politica_privacidade"
                    >Política de privacidade</router-link
                  >
                </li>
                
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
   
<script>
import AdmMenu from "../components/admin/AdmMenu.vue";
import AdmNavegation from "../components/admin/AdmNavegation.vue";
import DASHBOARD from "@/components/admin/homeAdm.vue";
import PAGAMENTO from "@/components/admin/pagamentoAdm.vue";
import CANAIS from "@/components/admin/canaisAdm.vue";
import ATENDENTES from "@/components/admin/usuariosAdm.vue";
import SETOR from "@/components/admin/setorAdm.vue";
import CONTATOS from "@/components/admin/contatosAdm.vue";
import SEGMENTOS from "@/components/admin/gruposAdm.vue";

import ENVIAMENSAGEM from "@/components/admin/campanhaAdm.vue";
import MIDIAS from "@/components/admin/midiasAdm.vue";
import ENVIOS from "@/components/admin/estatisticaCampanha.vue";
import CHATBOT from "@/components/admin/ChatBoot.vue";

import CONSULTADOUTOR from "@/components/admin/ConsultasDoutor.vue";
import PACIENTES from "@/components/admin/Pacientes.vue";
import CONSULTAPACIENTE from "@/components/admin/ConsultasPaciente.vue";
import REGISTRO from "@/components/admin/RegistrosConsulta.vue";

import ATUALIZARCADASTRO from "@/components/admin/AtualizarCadastro.vue";
import ABRIRCHAMADO from "@/components/admin/AbrirChamado.vue";
import ADDCHAMADO from "@/components/admin/acao/ADDCHAMADO.vue";

import Api from "@/services/api";

export default {
  name: "BannerAdm",
  components: {
    AdmMenu,
    AdmNavegation,
    DASHBOARD,
    CANAIS,
    ENVIAMENSAGEM,
    ENVIOS,
    ATENDENTES,
    SETOR,
    CONTATOS,
    PAGAMENTO,
    SEGMENTOS,
    MIDIAS,
    CHATBOT,
    ATUALIZARCADASTRO,
    ABRIRCHAMADO,
    ADDCHAMADO,

    CONSULTADOUTOR,
    CONSULTAPACIENTE,
    REGISTRO,
    PACIENTES
  },
  data() {
    return {
      titleMenu: "ADMIN MOB",
      dadosHome: null,
      selected: localStorage.getItem("@USER_PAGE"),
      

      options: [
        {
          name: "dashboard",
          target: "DASHBOARD",
          icone: "fas fa-tachometer-alt",
        },
        {
          name: "canais",
          target: "CANAIS",
          icone: "fas fa-phone-alt",
        },

        {
          name: "mensagens",
          target: "MENSAGENS",
          submenus: [
            {
              name: "envio de mensagens",
              target: "ENVIAMENSAGEM",
              icone: "fas fa-paper-plane",
            },
            {
              name: "estatísticas de envio",
              target: "ENVIOS",
              icone: "fas fa-chart-bar",
            },
            {
              name: "segmentos",
              target: "SEGMENTOS",
              icone: "fas fa-user-friends",
            },

            {
              name: "mídias",
              target: "MIDIAS",
              icone: "fas fa-folder-plus",
            },
          ],
          icone: "fas fa-paper-plane",
        },
            {
          name: "Cadastro",
          target: "CADASTRO",
          submenus: [
            {
              name: "atendentes",
              target: "ATENDENTES",
              icone: "fas fa-user-friends",
            },
            {
              name: "setor",
              target: "SETOR",
              icone: "fas fa-user-friends",
            },
          ],
          icone: "fas fa-paper-plane",
        },
        {
          name: "contatos",
          target: "CONTATOS",
          icone: "fas fa-user",
        },

        {
          name: "área de atendimento",
          target: "ATENDIMENTO",
          icone: "fas fa-headset",
        },
        {
          name: "pagamento",
          target: "PAGAMENTO",
          icone: "fas fa-wallet",
        },
        {
          name: "Configurar ChatBot",
          target: "CHATBOT",
          icone: "fas fa-cog",
        },
      ],
      chamados: [],
    };
  },
  computed: {
    currentSelectComponent: function () {
      return this.selected;
    },
  },
  methods: {
    buscarDadosChamados() {
      Api.post("/chamados/ZmlsYWRlYXRlbmRpbWVudG8=", {
        id: localStorage.getItem("@USER_ID"),
      })
        .then((success) => {
          let data = success.data;
          this.chamados = data.chamados;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    buscarDadosGraficos() {
      Api.post("/grafico/ZmlsYWRlYXRlbmRpbWVudG8=", {
        id: localStorage.getItem("@USER_ID"),
      })
        .then((success) => {
          let dados = success.data;
          this.dadosHome = dados;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setSelect(name) {
      this.selected = name;

      localStorage.setItem("@USER_PAGE", name);
    },
  },
};
</script>

<style>
.bg-transparent {
  background-color: transparent;
}
.bg-cinza {
  background-color: #e3eaef !important;
}
.main {
  width: 100vw;
}
.wrapper {
  overflow: hidden;
}
</style>
