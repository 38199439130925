<template>
  <div class="caixa">
    <BannerHome :data="data" />
    <BannerSolucoes />
    <BannerPlataforma :data="data" />
    <BannerApresentacao :data="data" />
    <BannerPlanos class="mt-4" />
    <BannerPerguntas />
    <BannerFooter :data="data" />
    <a href="https://api.whatsapp.com/send?phone=556541410643&text=Acessei%20o%20site%20inzupt.com.br%20e%20gostaria%20de%20mais%20informa%C3%A7%C3%A3o" class="link-flutuante">
      <img src="../assets/whatsapp.png" alt="" />
    </a>
  </div>
</template>

<script>
import BannerHome from "@/components/index/BannerHome.vue";
import BannerSolucoes from "@/components/index/BannerSolucoes.vue";
import BannerApresentacao from "@/components/index/BannerApresentacao.vue";
import BannerPlataforma from "@/components/index/BannerPlataforma.vue";
import BannerPlanos from "@/components/index/BannerPlanos.vue";
import BannerFooter from "@/components/index/BannerFooter.vue";
import Api from "@/services/api";
import BannerPerguntas from "@/components/index/BannerPerguntas.vue";
import Swal from "sweetalert2";
export default {
  name: "IndexPage",
  data() {
    return {
      data: [],
    };
  },
  components: {
    BannerHome,
    BannerSolucoes,
    BannerApresentacao,
    BannerPlataforma,
    BannerPlanos,
    BannerFooter,
    BannerPerguntas,
  },
  created() {
    localStorage.clear();
    this.front();
  },
  methods: {
    front() {
      Api.get("/front")
        .then((response) => {
          console.clear();
          this.data = response.data;
        })
        .catch((err) => {
          console.log(err);

          Swal.fire({
            position: "center",
            title: "Processando...",
            showConfirmButton: true,
            confirmButtonText: `<div class="spinner-border text-light" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>`,
            timer: 6000,
          });

          setTimeout(() => {
            this.front();
          }, 6000);
        });
    },
  },
};
</script>

<style>
button {
  outline: 0;
  border: 0;
}



.link-flutuante {
  position: fixed;
  bottom: 15px;
  right: 20px;
}




.bg-laranja {
  background-color: #f58634;
}
</style>
