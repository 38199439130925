<template>
  <nav id="sidebar" class="sidebar js-sidebar">
    <div class="sidebar-content js-simplebar">
      
      <div class="d-flex flex-column ps-2">
        <h1 class="font-roboto color-white fw-bold spacing fs-3 mt-5">
          ADMINISTRADOR
        </h1>
        <span class="text-uppercase font-roboto color-cinza spacing"
          >Painel</span
        >
      </div>
      <ul class="sidebar-nav mt-4">
        <li
          class="sidebar-item fs-4"
          :class="{ active: item.target == selected }"
          v-for="(item, index) in option"
          :key="item.target"
        >
          <router-link
            v-if="item.target === 'ATENDIMENTO'"
            target="_blank"
            to="/atendimento"
            class="sidebar-link"
          >
            <i class="icone" :class="item.icone"></i>
            <span class="align-middle font-roboto text-capitalize">{{
              item.name
            }}</span>
          </router-link>

          <a
            class="sidebar-link"
            @click="change(item.target)"
            v-else
            :href="'#multiCollapseExample' + index"
            role="button"
            data-bs-toggle="collapse"
            aria-expanded="false"
            :aria-controls="'multiCollapseExample' + index"
          >
            <i class="icone" :class="item.icone"></i>
            <span class="align-middle font-roboto text-capitalize">{{
              item.name
            }}</span>
          </a>

          <div
            class="collapse multi-collapse"
            :id="'multiCollapseExample' + index"
          >
            <li
              class="sidebar-item fs-sub"
              v-for="submenu in item.submenus"
              :key="submenu.target"
            >
              <a
                class="sidebar-link"
                href="#"
                @click="change(submenu.target)"
                style="padding-left: 40px"
              >
                <i class="icone fs-sub" :class="submenu.icone"></i>
                <span class="align-middle font-roboto text-capitalize">{{
                  submenu.name
                }}</span></a
              >
            </li>
          </div>
        </li>
        <li class="sidebar-item fs-4">
          <a class="sidebar-link" @click="deslogar()">
            <i class="fas fa-arrow-right"></i>
            <span class="align-middle font-roboto text-capitalize"> Sair </span>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>


<script>
import middleware from "@/services/middleware";
export default {
  name: "AdmMenu",

  methods: {
    deslogar() {
      middleware.logout();
    },
  },
  props: {
    option: Array,
    selected: String,
    change: { type: Function },
  },
};
</script>


<style scoped>
.color-white {
  color: #fff;
}
.color-cinza {
  color: #cfcfcf;
}
.icone {
  font-size: 1.1rem;
}
.li-color {
  color: #e9ecef;
}

nav {
  overflow-y: scroll;
}
::-webkit-scrollbar-track {
  background-color: #f4f4f4;
}
::-webkit-scrollbar {
  width: 10px;
  background: #837e7c;
}
::-webkit-scrollbar-thumb {
  background: #837e7c;
}

.spacing {
  letter-spacing: 1.2px;
}
.fs-sub {
  font-size: 15px;
}
</style>